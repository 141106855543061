import styled from "styled-components";
import { Card } from "semantic-ui-react";

const ProductContainer = styled.div`
  position: relative;
  min-height: 25rem;
`;

const Loading = styled.div`
  position: absolute;
  width: 100%;
  background: rgba(255, 255, 255, 0.527);
  font-size: 1.42857rem;
  display: flex;
  justify-content: center;
  padding-top: 20%;
  z-index: 10;
`;

const ProductCard = styled(Card)`
  min-width: 13rem;
  flex: 1;

  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2) !important;
  }

  .image {
    overflow: hidden;
  }

  img {
    transition: 0;
    -webkit-transition: all 0.2s ease-out;
  }

  img:hover {
    transition: 0;
    transform: 0;
    -webkit-transform: scale(1.06);
  }

  @media (max-width: 767px) {
    min-width: 15rem;
  }
`;

const FillerCard = styled(Card)`
  height: 0;
  min-width: 13rem;
  flex: 1;
  box-shadow: none !important;
  border: none;

  @media (max-width: 767px) {
    min-width: 15rem;
  }
`;
export { ProductContainer, Loading, ProductCard, FillerCard };
