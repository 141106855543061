import React from "react";

type Props = {
  category: string;
};

const ExplainDescription = ({ category }: Props): React.ReactElement => {
  const descriptions = {
    Common: (
      <>
        <b>Common</b>
        <p>The most common flash patterns.</p>
      </>
    ),
    Isophase: (
      <>
        <b>Isophase [ISO]</b>
        <p>A light in which all durations of light and darkness are equal.</p>
      </>
    ),
    "Single Flashing": (
      <>
        <b>Flashing</b>
        <p>
          A light in which the total duration of light in a period is shorter
          than the total duration of darkness and the appearances of light
          (flashes) are usually of equal duration.
        </p>
        <b>Single Flashing [FI]</b>
        <p>
          A flashing light in which a flash is regularly repeated (frequency not
          exceeding 30 flashes per minute).
        </p>
      </>
    ),
    "Single Occulting": (
      <>
        <b>Occulting</b>
        <p>
          A light in which the total duration of light in a period is longer
          than the total duration of darkness and the intervals of darkness
          (eclipses) are usually of equal duration.
        </p>
        <b>Single Occulting [OC]</b>
        <p>An occulting light in which an eclipse is regularly repeated.</p>
      </>
    ),
    "Group Flashing": (
      <>
        <b>Flashing</b>
        <p>
          A light in which the total duration of light in a period is shorter
          than the total duration of darkness and the appearances of light
          (flashes) are usually of equal duration.
        </p>
        <b>Group Flashing [FI (2)]</b>
        <p>
          A flashing light in which a group of flashes, specified in n umber, is
          regularly repeated.
        </p>
      </>
    ),
    "Long Flashing": (
      <>
        <b>Flashing</b>
        <p>
          A light in which the total duration of light in a period is shorter
          than the total duration of darkness and the appearances of light
          (flashes) are usually of equal duration.
        </p>
      </>
    ),
    "Composite Group Flash": (
      <>
        <b>Flashing</b>
        <p>
          A light in which the total duration of light in a period is shorter
          than the total duration of darkness and the appearances of light
          (flashes) are usually of equal duration.
        </p>
        <b>Composite Group Flashing [FI (2+1)]</b>
        <p>
          A light similar to a group flashing light except that successive
          groups in the period have different numbers of flashes.
        </p>
      </>
    ),
    "Continuous Quick": (
      <>
        <b>Quick</b>
        <p>
          A light in which flashes are produced at a rate of 60 flashes per
          minute.
        </p>
        <b>Continuous quick [Q]</b>
        <p>A quick light in which a flash is regularly repeated.</p>
      </>
    ),
    "Group Quick": (
      <>
        <b>Quick</b>
        <p>
          A light in which flashes are produced at a rate of 60 flashes per
          minute.
        </p>
      </>
    ),
    "Group Quick + Long Flash": (
      <>
        <b>Quick</b>
        <p>
          A light in which flashes are produced at a rate of 60 flashes per
          minute.
        </p>
      </>
    ),
    "Continuous Very Quick": (
      <>
        <b>Quick</b>
        <p>
          A light in which flashes are produced at a rate of 60 flashes per
          minute.
        </p>
        <b>Continuous quick [Q]</b>
        <p>A quick light in which a flash is regularly repeated.</p>
      </>
    ),
    "Continuous Very Quick + Long Flash": (
      <>
        <b>Quick</b>
        <p>
          A light in which flashes are produced at a rate of 60 flashes per
          minute.
        </p>
        <b>Continuous quick [Q]</b>
        <p>A quick light in which a flash is regularly repeated.</p>
      </>
    ),
    Morse: (
      <>
        <b>MORSE CODE [MO (A)]</b>
        <p>
          A light in which appearances of light of two clearly different
          durations (dots and dashes) are grouped to represent a character or
          characters in the Morse code.
        </p>
      </>
    ),
    "Group Occulting": <></>,
    Others: <></>,
    All: <></>,
  };

  return descriptions[
    category as
      | "Single Flashing"
      | "Single Occulting"
      | "Group Flashing"
      | "Long Flashing"
      | "Composite Group Flash"
      | "Continuous Quick"
      | "Group Quick"
      | "Group Quick + Long Flash"
      | "Continuous Very Quick"
      | "Continuous Very Quick + Long Flash"
      | "Morse"
      | "Others"
      | "All"
  ];
};

export default ExplainDescription;
