import React from "react";
import { Radio, Grid, DropdownProps } from "semantic-ui-react";
import { CategoriesList, CategoryCodes, CodeList } from "./Characters";
import { categoryOptions } from "./DataList";
import ExplainToast from "../Explain/Explain";
import { SettingsBlock } from "../index.styles";
import { Params, Settings } from "../../../types";

const styles = {
  grid: { marginTop: "0.5rem" },
  row: {
    padding: "0.5rem 0",
    alignItems: "center",
  },
  titleColumn: { paddingTop: "1.25rem", paddingBottom: 0 },
};

const Options = categoryOptions.map((opt: any) => ({
  ...opt,
  name: `${String(opt.id).padStart(3, "0")}: ${opt.name}`,
}));

type Props = {
  params: Params;
  updateSettings: (settings: Settings) => void;
};

const FlashCharacter = ({
  params,
  updateSettings,
}: Props): React.ReactElement => {
  const settings = params.settings;
  const selectedCategory = settings.flashCharacter.category;
  const selectedId = settings.flashCharacter.selectedId;

  let visibleCategories = Options.filter((opt) =>
    opt.category.includes(selectedCategory)
  );

  const onChangeValue = (e: React.FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const option = Options.find((opt) => opt.id === target.value);
    if (!option)
      throw new Error("Couldn't find flash character " + target.value);

    const newFlashCharacter = {
      ...settings.flashCharacter,
      selectedId: option.id,
      dutyCycle: option.dutyCycle,
      duration: option.duration,
    };
    // // When code is selected, keep group in sync.
    if (settings.flashCharacter.select === "code") {
      newFlashCharacter.category = option.category[0];
    }
    updateSettings({ ...settings, flashCharacter: newFlashCharacter });
  };

  const categorySelect = (data: DropdownProps) => {
    const value = String(data.value);
    let newSelection: string = selectedId;
    const option = Options.find((opt) => opt.id === selectedId);
    if (!option || !value)
      throw new Error("Couldn't find flash character category " + value);

    if (!option.category.includes(value)) {
      // If currently selected item is not in the newly selected category,
      // select the first item in that category
      newSelection =
        Options.find((opt) => opt.category.includes(value))?.id || "";
    }
    const newFlashCharacter = {
      ...settings.flashCharacter,
      category: value,
      selectedId: newSelection,
    };
    updateSettings({ ...settings, flashCharacter: newFlashCharacter });
  };

  const onSelectCategoryOrCode = (e: React.FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    if (!target) return;
    const newFlashCharacter = {
      ...settings.flashCharacter,
      select: target.id,
    };
    updateSettings({ ...settings, flashCharacter: newFlashCharacter });
  };

  return (
    <SettingsBlock>
      <ExplainToast
        table={
          params.settings.flashCharacter.select === "category"
            ? params.settings.flashCharacter.category
            : "All"
        }
      />

      <Grid style={styles.grid}>
        <Grid.Column
          computer={6}
          tablet={16}
          mobile={6}
          style={styles.titleColumn}
        >
          {/* Categories radio */}
          <Radio
            id="category"
            label="Categories"
            checked={settings.flashCharacter.select === "category"}
            onChange={onSelectCategoryOrCode}
          />
        </Grid.Column>
        <Grid.Column computer={10} tablet={16} mobile={10}>
          {/* Categories List */}
          <CategoriesList
            value={selectedCategory}
            handleSelect={categorySelect}
            disabled={settings.flashCharacter.select === "code"}
          />
        </Grid.Column>

        <Grid.Row style={styles.row}>
          <Grid.Column floated="right" computer={10} tablet={16} mobile={10}>
            {/* Category Codes */}
            <CategoryCodes
              value={selectedId}
              onChange={onChangeValue}
              categories={visibleCategories}
              disabled={settings.flashCharacter.select === "code"}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Column
          computer={6}
          tablet={16}
          mobile={6}
          style={styles.titleColumn}
        >
          {/* Code radio */}
          <Radio
            id="code"
            label="Code"
            checked={settings.flashCharacter.select === "code"}
            onChange={onSelectCategoryOrCode}
          />
        </Grid.Column>
        <Grid.Column computer={10} tablet={16} mobile={10}>
          {/* Code List */}
          <CodeList
            onChangeCode={onChangeValue}
            value={selectedId}
            options={Options}
            disabled={settings.flashCharacter.select === "category"}
          />
        </Grid.Column>
      </Grid>
    </SettingsBlock>
  );
};

export default FlashCharacter;
