import React, { useState } from "react";
import { ExplainTableHeader } from "../index.styles";
import { Button, DropdownProps, Header, Modal, Table } from "semantic-ui-react";
import { CategoriesList } from "../FlashCharacter/Characters";
import { categoryOptions } from "../FlashCharacter/DataList/FlashCharacterData";
import ExplainDescription from "./ExplainDescription";

type Props = {
  table: string;
};

const ExplainToast = (props: Props): React.ReactElement => {
  const [open, setOpen] = useState(false);

  return (
    <div id="settings-explain">
      <Modal
        closeIcon
        id="settings-explain-modal"
        onClose={() => setOpen(false)}
        open={open}
        trigger={
          <Button
            id="settings-explain-btn"
            icon="info"
            fluid
            size="mini"
            basic
            content={"Explanation"}
            positive
            onClick={() => setOpen(true)}
          />
        }
      >
        <Modal.Header>Explanation of lantern settings</Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            <Header>Array to Load Ratio (ALR)</Header>
            <p>
              Array to Load ratio (ALR). This is the ratio of Energy going in to
              the system (gathered by the solar panel “array”) to Energy leaving
              the system as LED light (the “load”). If ALR is less than one,
              there is not enough energy being received during the day to meet
              the LED power requirements during the night. For a safety margin
              to allow for variability in sunlight (cloudy or rainy days, etc…)
              solar designers usually want to see an ALR of a minimum 1.2
            </p>
            <Header>Effective Intensity</Header>
            <p>
              The effective intensity of a flashing light is equal to the
              intensity of a fixed light of the same colour which will produce
              the same visual range under identical conditions of observation.
              Effective intensity is measured in Candelas (cd).
            </p>
            <Header>Days of Autonomy</Header>
            <p>
              The number of days (i.e. 24-hour periods) a self-contained solar
              lantern may be expected to operate normally if all solar charging
              is removed when the battery is fully charged.
            </p>
            <Header>Transmissivity</Header>
            <p>
              A measure of how clear the atmosphere is. Atmospheric clarity is
              affected by several factors including smog, smoke particles and
              water vapour. In most cases, a transmissivity of 0.74 is used for
              range calculations, although in some especially clear tropical
              locations transmissivity of 0.85 is often used. The factor 0.74
              corresponds to meteorological visibility of 10 nautical miles.
            </p>
            <ExplainData table={props.table} />
          </Modal.Description>
        </Modal.Content>
      </Modal>
    </div>
  );
};

type ExplainDataProps = {
  table: string;
};

const ExplainData = (props: ExplainDataProps): React.ReactElement => {
  const [selectedCategory, setSelectedCategory] = useState(props.table);
  const categorySelect = (data: DropdownProps) => {
    if (!data.value) return;
    setSelectedCategory(String(data.value));
  };

  let options = categoryOptions;
  if (selectedCategory !== "All")
    options = options.filter((opt) => opt.category.includes(selectedCategory));

  const dutyCycle = (item: string) => `${Math.round(Number(item) * 100)}%`;

  return (
    <>
      <Header>Flash Character</Header>
      <CategoriesList
        scrolling
        includeAll
        value={selectedCategory}
        handleSelect={categorySelect}
        style={{ width: "22rem" }}
      />
      <br />
      <ExplainDescription category={selectedCategory} />
      <p>
        Each flash character is shown with the Carmanah Flash Code, the standard
        flash abbreviation (Character), the flash duty cycle, and the sequence
        of flash durations (FC1, FC2, etc.) and eclipse durations (EC1, EC2,
        etc.), in seconds.
      </p>

      <Table compact="very" celled unstackable striped>
        <ExplainTableHeader>
          <Table.HeaderCell>Flash code</Table.HeaderCell>
          <Table.HeaderCell>Character</Table.HeaderCell>
          <Table.HeaderCell>Duty cycle</Table.HeaderCell>
          <Table.HeaderCell>FC1</Table.HeaderCell>
          <Table.HeaderCell>EC1</Table.HeaderCell>
          <Table.HeaderCell>FC2</Table.HeaderCell>
          <Table.HeaderCell>EC2</Table.HeaderCell>
          <Table.HeaderCell>FC3</Table.HeaderCell>
          <Table.HeaderCell>EC3</Table.HeaderCell>
          <Table.HeaderCell>FC4</Table.HeaderCell>
          <Table.HeaderCell>EC4</Table.HeaderCell>
        </ExplainTableHeader>

        <Table.Body>
          {options.map((opt) => (
            <Table.Row>
              <Table.Cell>{opt.id}</Table.Cell>
              <Table.Cell>{opt.flashCharacter}</Table.Cell>
              <Table.Cell>{dutyCycle(opt.dutyCycle)}</Table.Cell>
              <Table.Cell>{opt.fc1}</Table.Cell>
              <Table.Cell>{opt.ec1}</Table.Cell>
              <Table.Cell>{opt.fc2}</Table.Cell>
              <Table.Cell>{opt.ec2}</Table.Cell>
              <Table.Cell>{opt.fc3}</Table.Cell>
              <Table.Cell>{opt.ec3}</Table.Cell>
              <Table.Cell>{opt.fc4}</Table.Cell>
              <Table.Cell>{opt.ec4}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};

export default ExplainToast;
