import React from "react";
import { Product } from "../../types";
import TableRow from "../common/ProductInfoTable/TableRow";

type Props = {
  lantern: Product;
};

type Divergences = {
  [key in keyof Product]?: number;
};

const divergences: Divergences = {
  divergence5: 5,
  divergence6: 6,
  divergence7: 7,
  divergence8: 8,
  divergence10: 10,
};

function findDivergence(lantern: Product, angleType: "x" | "w") {
  let divergence = null;
  Object.keys(divergences).forEach((key) => {
    if (lantern[key as keyof Product] === angleType) {
      divergence = divergences[key as keyof Divergences];
    }
  });

  return divergence;
}

export function DivergenceRow({ lantern }: Props) {
  let divergence = findDivergence(lantern, lantern.isWide ? "w" : "x");

  return (
    <TableRow
      title="Vertical Divergence"
      rowContent={`${divergence ?? "<n/a>"} °`}
    />
  );
}
