import React from "react";
import styled from "styled-components";

const Footer = styled.div`
  height: 4rem;
  color: #fff;
  background: #002f6c;
  margin-top: 4rem;

  @media (max-width: 767px) {
    height: 8rem;
  }
`;

const Content = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 2rem;
`;

const NavFooter = (): React.ReactElement => (
  <Footer>
    <Content>
      &copy;
      {" " + new Date().getFullYear() + " "}
      Sabik Marine. All Rights Reserved. Sabik Marine is a subsidiary of SPX
      Corporation.
    </Content>
  </Footer>
);

export default NavFooter;
