import React, { useState } from "react";

import { Form, Grid } from "semantic-ui-react";
import { Params, Settings } from "../../../types";

const transmissivityOptions = [
  { key: "0.74", text: "0.74", value: "0.74" },
  { key: "0.80", text: "0.80", value: "0.80" },
  { key: "0.85", text: "0.85", value: "0.85" },
];

type Props = {
  params: Params;
  updateSettings: (settings: Settings) => void;
};

const Transmissivity = ({
  params,
  updateSettings,
}: Props): React.ReactElement => {
  const settings = params.settings;

  const [fieldValue, setFieldValue] = useState(
    settings.transmissivity || transmissivityOptions[0].value
  );

  function onChangeValue(e: React.FormEvent<HTMLInputElement>) {
    const target = e.target as HTMLInputElement;
    const value = Number(target.value);
    updateSettings({ ...settings, transmissivity: value });
    setFieldValue(target.value);
  }

  return (
    <Grid.Row style={{ alignItems: "center", padding: 0 }}>
      <Grid.Column computer={7} tablet={8} mobile={8}>
        Transmissivity
      </Grid.Column>
      <Grid.Column computer={9} tablet={8} mobile={8}>
        <Form>
          <Form.Field
            control="select"
            value={fieldValue}
            onChange={onChangeValue}
          >
            {transmissivityOptions.map((item, i) => (
              <option key={item.key} value={item.value}>
                {item.text}
              </option>
            ))}
          </Form.Field>
        </Form>
      </Grid.Column>
    </Grid.Row>
  );
};

export default Transmissivity;
