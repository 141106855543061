import React from "react";
import Loader from "react-loader-spinner";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const LoadingSpinner = (): React.ReactElement => {
  return (
    <div>
      <header>
        <div>
          <Loader type="Puff" color="#0084D5" height={100} width={100} />
          <h5
            style={{
              textAlign: "center",
              marginTop: "0.5rem",
            }}
          >
            Loading...
          </h5>
        </div>
      </header>
    </div>
  );
};

export default LoadingSpinner;
