import React from "react";
import { ProductCard } from "./index.styles";
import { Link } from "react-router-dom";
import { Card, Image, Button, Icon, Placeholder } from "semantic-ui-react";
import ProductTable from "./ProductsTable";
import { Product } from "../../types";

type Props = {
  lantern: Product;
  loading: boolean;
};

const ProductsCard = (props: Props): React.ReactElement => (
  <ProductCard fluid key={props.lantern.productId}>
    <ProductsData {...props} />
  </ProductCard>
);

const ProductsData = (props: Props): React.ReactElement => {
  const lantern = props.lantern;
  const loading = props.loading;
  const {
    productId,
    productName,
    productImage,
    productShortDescription,
    productPage,
  } = lantern;

  return (
    <>
      {loading ? (
        <Placeholder>
          <Placeholder.Image square />
        </Placeholder>
      ) : (
        <Image
          as={Link}
          to={{ pathname: `/products/${productId}`, state: { modal: true } }}
          src={productImage}
          alt={productName}
        />
      )}

      <Card.Content>
        {loading ? (
          <Placeholder style={{ marginBottom: "1.3rem" }}>
            <Placeholder.Header>
              <Placeholder.Line length="short" />
              <Placeholder.Line length="long" />
            </Placeholder.Header>
            {[...Array(3)].map((_: any, i: number) => (
              <Placeholder.Paragraph key={i}>
                <Placeholder.Line key={"a" + i} />
                <Placeholder.Line key={"b" + i} />
              </Placeholder.Paragraph>
            ))}
            <Placeholder.Line length="long" />
          </Placeholder>
        ) : (
          <>
            <Card.Header
              as={Link}
              to={`/products/${productId}`}
              textAlign="center"
            >
              {productName}
            </Card.Header>

            <Card.Meta textAlign="center">{productShortDescription}</Card.Meta>

            <Card.Description>
              <ProductTable {...props} />
            </Card.Description>
          </>
        )}
      </Card.Content>

      <Card.Content
        extra
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingRight: 0,
          paddingLeft: 0,
        }}
      >
        <Button
          as="a"
          href={productPage}
          target="_new"
          icon
          disabled={loading}
          style={{ marginBottom: "0.5rem" }}
        >
          <Icon name="external alternate" /> Product information
        </Button>
        <Button
          icon
          as={Link}
          to={{
            pathname: `/products/${productId}`,
            state: { modal: true },
          }}
          disabled={loading}
        >
          <Icon name="zoom-in" /> Report
        </Button>
      </Card.Content>
    </>
  );
};

export default ProductsCard;
