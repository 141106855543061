import React from "react";
import { Table } from "semantic-ui-react";

type Props = {
  header?: string;
  unstackable?: boolean;
  children: React.ReactElement[];
};

const ProductInfoTable = (props: Props): React.ReactElement => {
  return (
    <Table unstackable={props.unstackable}>
      {props.header ? (
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan="3">{props.header}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
      ) : null}
      <Table.Body>{props.children}</Table.Body>
    </Table>
  );
};

export default ProductInfoTable;
