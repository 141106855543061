import * as React from "react";
import { Form } from "semantic-ui-react";
import { Params, Settings } from "../../types";
import { SettingsBlock } from "./index.styles";

type Props = {
  params: Params;
  updateSettings: (settings: Settings) => void;
};

const Features = ({ params, updateSettings }: Props): React.ReactElement => {
  const settings = params.settings;

  function onChangeBooleanValue(e: React.FormEvent<HTMLInputElement>) {
    const target = e.target as HTMLInputElement;
    updateSettings({
      ...settings,
      [target.id]: target.checked,
    });
  }

  return (
    <SettingsBlock>
      <Form style={{ fontSize: "1em" }}>
        <Form.Checkbox
          id="hasFeatureGPSSynchronization"
          label="GPS flash synchronization"
          onChange={onChangeBooleanValue}
          checked={settings.hasFeatureGPSSynchronization}
          style={{ fontSize: "1em" }}
        />
        <Form.Checkbox
          id="hasFeatureSatelliteMonitoring"
          label="Satellite monitoring"
          onChange={onChangeBooleanValue}
          checked={settings.hasFeatureSatelliteMonitoring}
          style={{ fontSize: "1em" }}
        />
        <Form.Checkbox
          id="hasFeatureGPSMonitoring"
          label="GSM monitoring"
          onChange={onChangeBooleanValue}
          checked={settings.hasFeatureGPSMonitoring}
          style={{ fontSize: "1em" }}
        />
        <Form.Checkbox
          id="hasFeatureBluetooth"
          label="Bluetooth"
          onChange={onChangeBooleanValue}
          checked={settings.hasFeatureBluetooth}
          style={{ fontSize: "1em" }}
        />
        <Form.Checkbox
          id="hasFeatureAIS"
          label="AIS transponder"
          onChange={onChangeBooleanValue}
          checked={settings.hasFeatureAIS}
          style={{ fontSize: "1em" }}
        />
      </Form>
    </SettingsBlock>
  );
};

export default Features;
