import React from "react";
import { Params } from "../../../types";

import ProductInfoTable from "../../common/ProductInfoTable";
import TableRow from "../../common/ProductInfoTable/TableRow";

type Props = { params: Params };

const LocationTable = ({ params }: Props): React.ReactElement => {
  const { location } = params;
  return (
    <ProductInfoTable header="Location">
      <TableRow
        title="Latitude:"
        rowContent={location.locationLatitude.toFixed(2)}
      />
      <TableRow
        title="Longitude:"
        rowContent={location.locationLongitude.toFixed(2)}
      />
    </ProductInfoTable>
  );
};

export default LocationTable;
