import React from "react";
import { useParams } from "react-router-dom";
import { Params, Product } from "../../../types";
import ProductInfoTable from "../../common/ProductInfoTable";
import TableRow from "../../common/ProductInfoTable/TableRow";
import { DivergenceRow } from "../../ProductSearch/renderDivergence";
import { categoryOptions } from "../../Settings/FlashCharacter/DataList";

type RowProps = {
  title: string;
  performance: number | string;
  float?: boolean;
  unit?: string;
};

const Row = ({
  title,
  performance,
  float,
  unit,
}: RowProps): React.ReactElement => {
  if (performance <= 0)
    return (
      <TableRow
        title={title}
        rowContent="Not supported"
        style={{ backgroundColor: "lightgray" }}
      />
    );
  if (typeof performance === "number")
    return (
      <TableRow
        title={title}
        rowContent={
          (float
            ? Math.round((performance + Number.EPSILON) * 100) / 100
            : Math.round(performance)) + (unit ? " " + unit : "")
        }
      />
    );
  else return <TableRow title={title} rowContent={performance} />;
};

type Props = {
  products: Product[];
  params: Params;
};

const PerformanceTable = ({ products, params }: Props): React.ReactElement => {
  const { productId } = useParams<{ productId: string }>();
  const product = products.find((p) => p.productId === productId);

  if (!product) return <>Jotain meni pieleen...</>;

  const { effectiveIntensity, range, daysOfAutonomy, minimumALR } = product;

  const color =
    params.settings.ledColor[0].toUpperCase() +
    params.settings.ledColor.substring(1);

  const flashCharacter = categoryOptions.filter(
    (category: any) => category.id === params.settings.flashCharacter.selectedId
  )[0];

  return (
    <ProductInfoTable header="Performance at location">
      <Row
        title="Effective Intensity"
        performance={effectiveIntensity}
        float
        unit="cd"
      />
      <Row
        title={"Range at T=" + params.settings.transmissivity}
        performance={range}
        float
        unit="NM"
      />
      <Row title="Days of Autonomy" performance={daysOfAutonomy} unit="days" />
      <Row title="Minimum ALR" performance={minimumALR} float />
      <Row
        title="Flash character"
        performance={flashCharacter.id + ": " + flashCharacter.name}
      />
      <Row title="Color" performance={color} />
      <DivergenceRow lantern={product} />
    </ProductInfoTable>
  );
};

export default PerformanceTable;
