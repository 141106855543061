import * as React from "react";
import { SettingsContainer, Divider, SettingsContact } from "./index.styles";
import { Header, Button } from "semantic-ui-react";

import Location from "./Location";
import Season from "./Season";
import FlashCharacter from "./FlashCharacter";
import Features from "./Features";
import PerformanceFilter from "./PerformanceFilter";
import Other from "./Other";
import { Params, Settings } from "../../types";

type Props = {
  params: Params;
  updateSettings: (settings: Settings) => void;
  loading: boolean;
};

const SearchSettings = ({
  params,
  updateSettings,
  loading,
}: Props): React.ReactElement => (
  <SettingsContainer>
    <Header
      as="h2"
      textAlign="center"
      style={{
        margin: "0 -0.2rem",
      }}
    >
      CURRENT LOCATION
    </Header>
    <Divider />

    <Location params={params} />
    <Divider />

    <Season updateSettings={updateSettings} params={params} />
    <Divider />

    <Header
      as="h2"
      textAlign="center"
      style={{
        margin: 0,
      }}
    >
      LANTERN SETTINGS
    </Header>
    <Divider />

    <FlashCharacter updateSettings={updateSettings} params={params} />
    <Divider />

    <Features updateSettings={updateSettings} params={params} />
    <Divider />

    <PerformanceFilter updateSettings={updateSettings} params={params} />
    <Divider />

    <Other updateSettings={updateSettings} params={params} />
    <Divider />

    <SettingsContact>
      <Header as="h2">Want to know more?</Header>
      <div>
        Our team is willing to help you with any questions regarding selecting
        the right self-contained lanterns for your purpose.
      </div>
      <Button
        href="https://marine.sabik.com/contact-us-en"
        target="_blank"
        variant="primary"
        style={{ marginTop: "1rem" }}
      >
        Contact Us
      </Button>
    </SettingsContact>
  </SettingsContainer>
);

export default SearchSettings;
