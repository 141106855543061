import React from "react";
import { useParams } from "react-router-dom";

import { Grid, Image, Header } from "semantic-ui-react";
import { Product } from "../../../types";

type Props = {
  products: Product[];
};

const Description = ({ products }: Props): React.ReactElement => {
  const { productId } = useParams<{ productId: string }>();
  const product = products.find((p) => p.productId === productId);

  if (!product) return <>Jotain meni pieleen...</>;

  return (
    <Grid className="description">
      {/* Tablet header */}
      <Grid.Column width={16} only="tablet">
        <Header
          as="h1"
          content={product.productName}
          subheader={product.productShortDescription}
          style={{ marginBottom: "0.5rem" }}
        />
      </Grid.Column>

      {/* Mobile header */}
      <Grid.Column width={16} only="mobile">
        <Header
          as="h1"
          content={product.productName}
          subheader={product.productShortDescription}
          style={{ textAlign: "center" }}
        />
      </Grid.Column>

      {/* Tablet and computer image */}
      <Grid.Column computer={5} tablet={5} only="tablet computer">
        <Image src={product.productImage} alt={product.productName} rounded />
      </Grid.Column>

      {/* Mobile picture */}
      <Grid.Column mobile={16} only="mobile">
        <Image
          src={product.productImage}
          alt={product.productName}
          rounded
          style={{ width: "100%", maxWidth: "20rem", margin: "0 auto" }}
        />
      </Grid.Column>

      {/* Computer header and description */}
      <Grid.Column width={11} only="computer">
        <Header
          as="h1"
          content={product.productName}
          subheader={product.productShortDescription}
          style={{ marginBottom: "0.5rem" }}
        />
        <Header as="h3">Description</Header>
        {product.productDescription}
      </Grid.Column>

      {/* Mobile and tablet description */}
      <Grid.Column tablet={11} mobile={16} only="tablet mobile">
        <Header as="h3">Description</Header>
        {product.productDescription}
      </Grid.Column>
    </Grid>
  );
};

export default Description;
