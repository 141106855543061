// AUTOGENERATED FILE, DO NOT EDIT BY HAND
// See buildFlashDataTable.js
// prettier-ignore
export const categoryOptions = [
 { id: "001", duration: "1", flashCharacter: " Fixed Light", dutyCycle: "1", name: "Fixed Light", category: ["Common"], fc1: undefined, fc2: undefined, fc3: undefined, fc4: undefined, ec1: undefined, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "002", duration: "0.5", flashCharacter: "Fl(2) 10s", dutyCycle: "0.1", name: "Fl(2) 10s 10%", category: ["Group Flashing"], fc1: 0.5, fc2: 0.5, fc3: undefined, fc4: undefined, ec1: 1, ec2: 8, ec3: undefined, ec4: undefined },
 { id: "003", duration: "0.5", flashCharacter: "Fl(2) 10s", dutyCycle: "0.1", name: "Fl(2) 10s 10%", category: ["Group Flashing"], fc1: 0.5, fc2: 0.5, fc3: undefined, fc4: undefined, ec1: 1.5, ec2: 7.5, ec3: undefined, ec4: undefined },
 { id: "004", duration: "0.8", flashCharacter: "Fl(2) 10s", dutyCycle: "0.16", name: "Fl(2) 10s 16%", category: ["Group Flashing"], fc1: 0.8, fc2: 0.8, fc3: undefined, fc4: undefined, ec1: 1.2, ec2: 7.2, ec3: undefined, ec4: undefined },
 { id: "005", duration: "1", flashCharacter: "Fl(2) 10s", dutyCycle: "0.2", name: "Fl(2) 10s 20%", category: ["Group Flashing"], fc1: 1, fc2: 1, fc3: undefined, fc4: undefined, ec1: 1, ec2: 7, ec3: undefined, ec4: undefined },
 { id: "006", duration: "1", flashCharacter: "Fl(2) 10s", dutyCycle: "0.2", name: "Fl(2) 10s 20%", category: ["Group Flashing"], fc1: 1, fc2: 1, fc3: undefined, fc4: undefined, ec1: 1.5, ec2: 6.5, ec3: undefined, ec4: undefined },
 { id: "007", duration: "0.5", flashCharacter: "Fl(2) 12s", dutyCycle: "0.08333333333333333", name: "Fl(2) 12s 8%", category: ["Group Flashing"], fc1: 0.5, fc2: 0.5, fc3: undefined, fc4: undefined, ec1: 1, ec2: 10, ec3: undefined, ec4: undefined },
 { id: "008", duration: "1.5", flashCharacter: "Fl(2) 12s", dutyCycle: "0.25", name: "Fl(2) 12s 25%", category: ["Group Flashing"], fc1: 1.5, fc2: 1.5, fc3: undefined, fc4: undefined, ec1: 2, ec2: 7, ec3: undefined, ec4: undefined },
 { id: "009", duration: "1", flashCharacter: "Fl(2) 15s", dutyCycle: "0.13333333333333333", name: "Fl(2) 15s 13%", category: ["Group Flashing"], fc1: 1, fc2: 1, fc3: undefined, fc4: undefined, ec1: 2, ec2: 11, ec3: undefined, ec4: undefined },
 { id: "010", duration: "0.5", flashCharacter: "Fl(2) 5s", dutyCycle: "0.2", name: "Fl(2) 5s 20%", category: ["Group Flashing"], fc1: 0.5, fc2: 0.5, fc3: undefined, fc4: undefined, ec1: 1, ec2: 3, ec3: undefined, ec4: undefined },
 { id: "011", duration: "1", flashCharacter: "Fl(2) 5s", dutyCycle: "0.4", name: "Fl(2) 5s 40%", category: ["Group Flashing"], fc1: 1, fc2: 1, fc3: undefined, fc4: undefined, ec1: 1, ec2: 2, ec3: undefined, ec4: undefined },
 { id: "012", duration: "0.5", flashCharacter: "Fl(2) 6s", dutyCycle: "0.16666666666666666", name: "Fl(2) 6s 17%", category: ["Group Flashing", "Common"], fc1: 0.5, fc2: 0.5, fc3: undefined, fc4: undefined, ec1: 1, ec2: 4, ec3: undefined, ec4: undefined },
 { id: "013", duration: "0.8", flashCharacter: "Fl(2) 6s", dutyCycle: "0.26666666666666666", name: "Fl(2) 6s 27%", category: ["Group Flashing"], fc1: 0.8, fc2: 0.8, fc3: undefined, fc4: undefined, ec1: 1.2, ec2: 3.2, ec3: undefined, ec4: undefined },
 { id: "014", duration: "1", flashCharacter: "Fl(2) 6s", dutyCycle: "0.3333333333333333", name: "Fl(2) 6s 33%", category: ["Group Flashing"], fc1: 1, fc2: 1, fc3: undefined, fc4: undefined, ec1: 1, ec2: 3, ec3: undefined, ec4: undefined },
 { id: "015", duration: "1", flashCharacter: "Fl(2) 7s", dutyCycle: "0.2857142857142857", name: "Fl(2) 7s 29%", category: ["Group Flashing"], fc1: 1, fc2: 1, fc3: undefined, fc4: undefined, ec1: 1, ec2: 4, ec3: undefined, ec4: undefined },
 { id: "016", duration: "0.5", flashCharacter: "Fl(2) 8s", dutyCycle: "0.125", name: "Fl(2) 8s 13%", category: ["Group Flashing", "Common"], fc1: 0.5, fc2: 0.5, fc3: undefined, fc4: undefined, ec1: 1, ec2: 6, ec3: undefined, ec4: undefined },
 { id: "017", duration: "1", flashCharacter: "Fl(2) 8s", dutyCycle: "0.25", name: "Fl(2) 8s 25%", category: ["Group Flashing"], fc1: 1, fc2: 1, fc3: undefined, fc4: undefined, ec1: 1, ec2: 5, ec3: undefined, ec4: undefined },
 { id: "018", duration: "0.5", flashCharacter: "Fl(2+1) 10s", dutyCycle: "0.15", name: "Fl(2+1) 10s 15%", category: ["Composite Group Flash"], fc1: 0.5, fc2: 0.5, fc3: 0.5, fc4: undefined, ec1: 0.7, ec2: 2.1, ec3: 5.7, ec4: undefined },
 { id: "019", duration: "0.8", flashCharacter: "Fl(2+1) 12s", dutyCycle: "0.20000000000000004", name: "Fl(2+1) 12s 20%", category: ["Composite Group Flash"], fc1: 0.8, fc2: 0.8, fc3: 0.8, fc4: undefined, ec1: 1.2, ec2: 2.4, ec3: 6, ec4: undefined },
 { id: "020", duration: "1", flashCharacter: "Fl(2+1) 12s", dutyCycle: "0.25", name: "Fl(2+1) 12s 25%", category: ["Composite Group Flash"], fc1: 1, fc2: 1, fc3: 1, fc4: undefined, ec1: 1, ec2: 4, ec3: 4, ec4: undefined },
 { id: "021", duration: "1", flashCharacter: "Fl(2+1) 15s", dutyCycle: "0.2", name: "Fl(2+1) 15s 20%", category: ["Composite Group Flash"], fc1: 1, fc2: 1, fc3: 1, fc4: undefined, ec1: 2, ec2: 5, ec3: 5, ec4: undefined },
 { id: "022", duration: "0.3", flashCharacter: "Fl(2+1) 6s", dutyCycle: "0.15", name: "Fl(2+1) 6s 15%", category: ["Composite Group Flash"], fc1: 0.3, fc2: 0.3, fc3: 0.3, fc4: undefined, ec1: 0.4, ec2: 1.2, ec3: 3.5, ec4: undefined },
 { id: "023", duration: "0.5", flashCharacter: "Fl(3) 12S", dutyCycle: "0.125", name: "Fl(3) 12S 13%", category: ["Group Flashing"], fc1: 0.5, fc2: 0.5, fc3: 0.5, fc4: undefined, ec1: 2, ec2: 2, ec3: 6.5, ec4: undefined },
 { id: "024", duration: "0.5", flashCharacter: "Fl(3) 10s", dutyCycle: "0.15", name: "Fl(3) 10s 15%", category: ["Group Flashing"], fc1: 0.5, fc2: 0.5, fc3: 0.5, fc4: undefined, ec1: 1.5, ec2: 1.5, ec3: 5.5, ec4: undefined },
 { id: "025", duration: "1", flashCharacter: "Fl(3) 10s", dutyCycle: "0.3", name: "Fl(3) 10s 30%", category: ["Group Flashing"], fc1: 1, fc2: 1, fc3: 1, fc4: undefined, ec1: 1, ec2: 1, ec3: 5, ec4: undefined },
 { id: "026", duration: "0.8", flashCharacter: "Fl(3) 12s", dutyCycle: "0.20000000000000004", name: "Fl(3) 12s 20%", category: ["Group Flashing"], fc1: 0.8, fc2: 0.8, fc3: 0.8, fc4: undefined, ec1: 1.2, ec2: 1.2, ec3: 7.2, ec4: undefined },
 { id: "027", duration: "0.3", flashCharacter: "Fl(3) 15s", dutyCycle: "0.05999999999999999", name: "Fl(3) 15s 6%", category: ["Group Flashing"], fc1: 0.3, fc2: 0.3, fc3: 0.3, fc4: undefined, ec1: 1.7, ec2: 1.7, ec3: 10.7, ec4: undefined },
 { id: "028", duration: "0.5", flashCharacter: "Fl(3) 15s", dutyCycle: "0.1", name: "Fl(3) 15s 10%", category: ["Group Flashing"], fc1: 0.5, fc2: 0.5, fc3: 0.5, fc4: undefined, ec1: 1.5, ec2: 1.5, ec3: 10.5, ec4: undefined },
 { id: "029", duration: "0.5", flashCharacter: "Fl(3) 20s", dutyCycle: "0.075", name: "Fl(3) 20s 8%", category: ["Group Flashing"], fc1: 0.5, fc2: 0.5, fc3: 0.5, fc4: undefined, ec1: 3, ec2: 3, ec3: 12.5, ec4: undefined },
 { id: "030", duration: "0.8", flashCharacter: "Fl(3) 9s", dutyCycle: "0.2666666666666667", name: "Fl(3) 9s 27%", category: ["Group Flashing"], fc1: 0.8, fc2: 0.8, fc3: 0.8, fc4: undefined, ec1: 1.2, ec2: 1.2, ec3: 4.2, ec4: undefined },
 { id: "031", duration: "0.5", flashCharacter: "Fl(4) 10s", dutyCycle: "0.2", name: "Fl(4) 10s 20%", category: ["Group Flashing"], fc1: 0.5, fc2: 0.5, fc3: 0.5, fc4: 0.5, ec1: 1, ec2: 1, ec3: 1, ec4: 5 },
 { id: "032", duration: "0.8", flashCharacter: "Fl(4) 10s", dutyCycle: "0.32", name: "Fl(4) 10s 32%", category: ["Group Flashing"], fc1: 0.8, fc2: 0.8, fc3: 0.8, fc4: 0.8, ec1: 1.2, ec2: 1.2, ec3: 1.2, ec4: 3.2 },
 { id: "033", duration: "0.8", flashCharacter: "Fl(4) 12s", dutyCycle: "0.26666666666666666", name: "Fl(4) 12s 27%", category: ["Group Flashing"], fc1: 0.8, fc2: 0.8, fc3: 0.8, fc4: 0.8, ec1: 1.2, ec2: 1.2, ec3: 1.2, ec4: 5.2 },
 { id: "034", duration: "0.5", flashCharacter: "Fl(4) 15s", dutyCycle: "0.13333333333333333", name: "Fl(4) 15s 13%", category: ["Group Flashing"], fc1: 0.5, fc2: 0.5, fc3: 0.5, fc4: 0.5, ec1: 1.5, ec2: 1.5, ec3: 1.5, ec4: 8.5 },
 { id: "035", duration: "1", flashCharacter: "Fl(4) 15s", dutyCycle: "0.26666666666666666", name: "Fl(4) 15s 27%", category: ["Group Flashing"], fc1: 1, fc2: 1, fc3: 1, fc4: 1, ec1: 1, ec2: 1, ec3: 1, ec4: 8 },
 { id: "036", duration: "0.5", flashCharacter: "Fl(4) 20s", dutyCycle: "0.1", name: "Fl(4) 20s 10%", category: ["Group Flashing"], fc1: 0.5, fc2: 0.5, fc3: 0.5, fc4: 0.5, ec1: 1.5, ec2: 1.5, ec3: 1.5, ec4: 13.5 },
 { id: "037", duration: "0.8", flashCharacter: "Fl(5) 20s", dutyCycle: "0.2", name: "Fl(5) 20s 20%", category: ["Group Flashing"], fc1: 0.8, fc2: 0.8, fc3: 0.8, fc4: 0.8, ec1: 1.2, ec2: 1.2, ec3: 1.2, ec4: 1.2 },
 { id: "038", duration: "1", flashCharacter: "Fl(5) 20s", dutyCycle: "0.25", name: "Fl(5) 20s 25%", category: ["Group Flashing"], fc1: 1, fc2: 1, fc3: 1, fc4: 1, ec1: 1, ec2: 1, ec3: 1, ec4: 1 },
 { id: "039", duration: "0.5", flashCharacter: "Fl(6) 15s", dutyCycle: "0.2", name: "Fl(6) 15s 20%", category: ["Group Flashing"], fc1: 0.5, fc2: 0.5, fc3: 0.5, fc4: 0.5, ec1: 1, ec2: 1, ec3: 1, ec4: 1 },
 { id: "040", duration: "1", flashCharacter: "FL-*", dutyCycle: "0.75", name: "FL-* 75%", category: ["Others"], fc1: 5, fc2: 1, fc3: undefined, fc4: undefined, ec1: 1, ec2: 1, ec3: undefined, ec4: undefined },
 { id: "041", duration: "1", flashCharacter: "FL-**", dutyCycle: "0.7", name: "FL-** 70%", category: ["Others"], fc1: 5, fc2: 1, fc3: 1, fc4: undefined, ec1: 1, ec2: 1, ec3: 1, ec4: undefined },
 { id: "042", duration: "0.3", flashCharacter: "Fl 1.5s", dutyCycle: "0.19999999999999998", name: "Fl 1.5s 20%", category: ["Single Flashing"], fc1: 0.3, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 1.2, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "043", duration: "0.5", flashCharacter: "Fl 1.5s", dutyCycle: "0.3333333333333333", name: "Fl 1.5s 33%", category: ["Single Flashing", "Common"], fc1: 0.5, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 1, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "044", duration: "0.5", flashCharacter: "Fl 10s", dutyCycle: "0.05", name: "Fl 10s 5%", category: ["Single Flashing", "Common"], fc1: 0.5, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 9.5, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "045", duration: "1", flashCharacter: "Fl 10s", dutyCycle: "0.1", name: "Fl 10s 10%", category: ["Single Flashing"], fc1: 1, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 9, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "046", duration: "1.5", flashCharacter: "Fl 10s", dutyCycle: "0.15", name: "Fl 10s 15%", category: ["Single Flashing"], fc1: 1.5, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 8.5, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "047", duration: "1.2", flashCharacter: "Fl 12s", dutyCycle: "0.09999999999999999", name: "Fl 12s 10%", category: ["Single Flashing"], fc1: 1.2, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 10.8, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "048", duration: "1", flashCharacter: "Fl 15s", dutyCycle: "0.06666666666666667", name: "Fl 15s 7%", category: ["Single Flashing"], fc1: 1, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 14, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "049", duration: "0.3", flashCharacter: "Fl 2.5s", dutyCycle: "0.12", name: "Fl 2.5s 12%", category: ["Single Flashing", "Common"], fc1: 0.3, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 2.2, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "050", duration: "0.5", flashCharacter: "Fl 2.5s", dutyCycle: "0.2", name: "Fl 2.5s 20%", category: ["Single Flashing", "Common"], fc1: 0.5, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 2, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "051", duration: "0.3", flashCharacter: "Fl 2.8s", dutyCycle: "0.10714285714285715", name: "Fl 2.8s 11%", category: ["Single Flashing", "Common"], fc1: 0.3, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 2.5, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "052", duration: "0.2", flashCharacter: "Fl 2s", dutyCycle: "0.1", name: "Fl 2s 10%", category: ["Single Flashing", "Common"], fc1: 0.2, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 1.8, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "053", duration: "0.3", flashCharacter: "Fl 2s", dutyCycle: "0.15", name: "Fl 2s 15%", category: ["Single Flashing"], fc1: 0.3, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 1.7, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "054", duration: "0.4", flashCharacter: "Fl 2s", dutyCycle: "0.2", name: "Fl 2s 20%", category: ["Single Flashing"], fc1: 0.4, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 1.6, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "055", duration: "0.5", flashCharacter: "Fl 2s", dutyCycle: "0.25", name: "Fl 2s 25%", category: ["Single Flashing", "Common"], fc1: 0.5, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 1.5, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "056", duration: "0.7", flashCharacter: "Fl 2s", dutyCycle: "0.35", name: "Fl 2s 35%", category: ["Single Flashing"], fc1: 0.7, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 1.3, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "057", duration: "0.8", flashCharacter: "Fl 2s", dutyCycle: "0.4", name: "Fl 2s 40%", category: ["Single Flashing"], fc1: 0.8, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 1.2, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "058", duration: "0.3", flashCharacter: "Fl 3s", dutyCycle: "0.09999999999999999", name: "Fl 3s 10%", category: ["Single Flashing", "Common"], fc1: 0.3, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 2.7, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "059", duration: "0.5", flashCharacter: "Fl 3s", dutyCycle: "0.16666666666666666", name: "Fl 3s 17%", category: ["Single Flashing", "Common"], fc1: 0.5, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 2.5, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "060", duration: "0.7", flashCharacter: "Fl 3s", dutyCycle: "0.2333333333333333", name: "Fl 3s 23%", category: ["Single Flashing", "Common"], fc1: 0.7, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 2.3, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "061", duration: "1", flashCharacter: "Fl 3s", dutyCycle: "0.3333333333333333", name: "Fl 3s 33%", category: ["Single Flashing", "Common"], fc1: 1, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 2, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "062", duration: "1.3", flashCharacter: "Fl 4.3s", dutyCycle: "0.3023255813953489", name: "Fl 4.3s 30%", category: ["Single Flashing"], fc1: 1.3, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 3, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "063", duration: "0.4", flashCharacter: "Fl 4.4s", dutyCycle: "0.09090909090909091", name: "Fl 4.4s 9%", category: ["Single Flashing", "Common"], fc1: 0.4, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 4, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "064", duration: "0.5", flashCharacter: "Fl 4s", dutyCycle: "0.125", name: "Fl 4s 13%", category: ["Single Flashing", "Common"], fc1: 0.5, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 3.5, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "065", duration: "0.8", flashCharacter: "Fl 4s", dutyCycle: "0.2", name: "Fl 4s 20%", category: ["Single Flashing"], fc1: 0.8, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 3.2, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "066", duration: "1", flashCharacter: "Fl 4s", dutyCycle: "0.25", name: "Fl 4s 25%", category: ["Single Flashing", "Common"], fc1: 1, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 3, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "067", duration: "1.5", flashCharacter: "Fl 4s", dutyCycle: "0.375", name: "Fl 4s 38%", category: ["Single Flashing"], fc1: 1.5, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 2.5, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "068", duration: "0.3", flashCharacter: "Fl 5s", dutyCycle: "0.06", name: "Fl 5s 6%", category: ["Single Flashing", "Common"], fc1: 0.3, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 4.7, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "069", duration: "0.5", flashCharacter: "Fl 5s", dutyCycle: "0.1", name: "Fl 5s 10%", category: ["Single Flashing", "Common"], fc1: 0.5, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 4.5, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "070", duration: "1", flashCharacter: "Fl 5s", dutyCycle: "0.2", name: "Fl 5s 20%", category: ["Single Flashing", "Common"], fc1: 1, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 4, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "071", duration: "1.5", flashCharacter: "Fl 5s", dutyCycle: "0.25", name: "Fl 5s 25%", category: ["Single Flashing"], fc1: 1.5, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 4.5, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "072", duration: "0.5", flashCharacter: "Fl 6s", dutyCycle: "0.08333333333333333", name: "Fl 6s 8%", category: ["Single Flashing", "Common"], fc1: 0.5, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 5.5, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "073", duration: "0.6", flashCharacter: "Fl 6s", dutyCycle: "0.09999999999999999", name: "Fl 6s 10%", category: ["Single Flashing"], fc1: 0.6, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 5.4, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "074", duration: "1", flashCharacter: "Fl 6s", dutyCycle: "0.16666666666666666", name: "Fl 6s 17%", category: ["Single Flashing"], fc1: 1, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 5, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "075", duration: "1.5", flashCharacter: "Fl 6s", dutyCycle: "0.3", name: "Fl 6s 30%", category: ["Single Flashing"], fc1: 1.5, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 3.5, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "076", duration: "0.8", flashCharacter: "Fl 7.5s", dutyCycle: "0.10666666666666667", name: "Fl 7.5s 11%", category: ["Single Flashing"], fc1: 0.8, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 6.7, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "077", duration: "5", flashCharacter: "ISO 10S", dutyCycle: "0.5", name: "ISO 10S 50%", category: ["Isophase"], fc1: 5, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 5, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "078", duration: "1", flashCharacter: "ISO 2S", dutyCycle: "0.5", name: "ISO 2S 50%", category: ["Isophase", "Common"], fc1: 1, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 1, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "079", duration: "2", flashCharacter: "ISO 4S", dutyCycle: "0.5", name: "ISO 4S 50%", category: ["Isophase", "Common"], fc1: 2, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 2, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "080", duration: "2.5", flashCharacter: "ISO 5S", dutyCycle: "0.5", name: "ISO 5S 50%", category: ["Isophase"], fc1: 2.5, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 2.5, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "081", duration: "3", flashCharacter: "ISO 6S", dutyCycle: "0.5", name: "ISO 6S 50%", category: ["Isophase"], fc1: 3, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 3, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "082", duration: "4", flashCharacter: "ISO 8S", dutyCycle: "0.5", name: "ISO 8S 50%", category: ["Isophase"], fc1: 4, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 4, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "083", duration: "1.5", flashCharacter: "ISO 3S", dutyCycle: "0.5", name: "ISO 3S 50%", category: ["Isophase"], fc1: 1.5, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 1.5, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "084", duration: "2", flashCharacter: "LFL 10S", dutyCycle: "0.2", name: "LFL 10S 20%", category: ["Long Flashing"], fc1: 2, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 8, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "085", duration: "3", flashCharacter: "LFL 10S", dutyCycle: "0.3", name: "LFL 10S 30%", category: ["Long Flashing"], fc1: 3, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 7, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "086", duration: "4", flashCharacter: "LFL 10S", dutyCycle: "0.4", name: "LFL 10S 40%", category: ["Long Flashing"], fc1: 4, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 6, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "087", duration: "2", flashCharacter: "LFL 12S", dutyCycle: "0.16666666666666666", name: "LFL 12S 17%", category: ["Long Flashing"], fc1: 2, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 10, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "088", duration: "4", flashCharacter: "LFL 15S", dutyCycle: "0.26666666666666666", name: "LFL 15S 27%", category: ["Long Flashing"], fc1: 4, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 11, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "089", duration: "2", flashCharacter: "LFL 5S", dutyCycle: "0.4", name: "LFL 5S 40%", category: ["Long Flashing"], fc1: 2, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 3, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "090", duration: "2", flashCharacter: "LFL 6S", dutyCycle: "0.3333333333333333", name: "LFL 6S 33%", category: ["Long Flashing"], fc1: 2, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 4, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "091", duration: "2", flashCharacter: "LFL 8S", dutyCycle: "0.25", name: "LFL 8S 25%", category: ["Long Flashing"], fc1: 2, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 6, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "092", duration: "3", flashCharacter: "LFL 8S", dutyCycle: "0.375", name: "LFL 8S 38%", category: ["Long Flashing"], fc1: 3, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 5, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "093", duration: "0.5", flashCharacter: "MO(A) 10S", dutyCycle: "0.2", name: "MO(A) 10S 20%", category: ["Morse"], fc1: 0.5, fc2: 1.5, fc3: undefined, fc4: undefined, ec1: 0.5, ec2: 7.5, ec3: undefined, ec4: undefined },
 { id: "094", duration: "0.5", flashCharacter: "MO(A) 15s", dutyCycle: "0.16666666666666666", name: "MO(A) 15s 17%", category: ["Morse"], fc1: 0.5, fc2: 2, fc3: undefined, fc4: undefined, ec1: 1.5, ec2: 11, ec3: undefined, ec4: undefined },
 { id: "095", duration: "0.3", flashCharacter: "MO(A) 6s", dutyCycle: "0.21666666666666667", name: "MO(A) 6s 22%", category: ["Morse"], fc1: 0.3, fc2: 1, fc3: undefined, fc4: undefined, ec1: 0.6, ec2: 4.1, ec3: undefined, ec4: undefined },
 { id: "096", duration: "0.8", flashCharacter: "MO(A) 8s", dutyCycle: "0.4", name: "MO(A) 8s 40%", category: ["Morse"], fc1: 0.8, fc2: 2.4, fc3: undefined, fc4: undefined, ec1: 1.2, ec2: 3.6, ec3: undefined, ec4: undefined },
 { id: "097", duration: "0.5", flashCharacter: "MO(B) 15S", dutyCycle: "0.2", name: "MO(B) 15S 20%", category: ["Morse"], fc1: 1.5, fc2: 0.5, fc3: 0.5, fc4: 0.5, ec1: 0.5, ec2: 0.5, ec3: 0.5, ec4: 10.5 },
 { id: "098", duration: "0.3", flashCharacter: "MO(U) 10S", dutyCycle: "0.15", name: "MO(U) 10S 15%", category: ["Morse", "Common"], fc1: 0.3, fc2: 0.3, fc3: 0.9, fc4: undefined, ec1: 0.7, ec2: 0.7, ec3: 7.1, ec4: undefined },
 { id: "099", duration: "0.4", flashCharacter: "MO(U) 10S", dutyCycle: "0.2", name: "MO(U) 10S 20%", category: ["Morse", "Common"], fc1: 0.4, fc2: 0.4, fc3: 1.2, fc4: undefined, ec1: 0.6, ec2: 0.6, ec3: 6.8, ec4: undefined },
 { id: "100", duration: "0.5", flashCharacter: "MO(U) 10S", dutyCycle: "0.25", name: "MO(U) 10S 25%", category: ["Morse"], fc1: 0.5, fc2: 0.5, fc3: 1.5, fc4: undefined, ec1: 0.5, ec2: 0.5, ec3: 6.5, ec4: undefined },
 { id: "101", duration: "0.5", flashCharacter: "MO(U) 15S", dutyCycle: "0.16666666666666666", name: "MO(U) 15S 17%", category: ["Morse"], fc1: 0.5, fc2: 0.5, fc3: 1.5, fc4: undefined, ec1: 0.5, ec2: 0.5, ec3: 11.5, ec4: undefined },
 { id: "102", duration: "0.6", flashCharacter: "MO(U) 15S", dutyCycle: "0.1733333333333333", name: "MO(U) 15S 17%", category: ["Morse"], fc1: 0.6, fc2: 0.6, fc3: 1.4, fc4: undefined, ec1: 0.3, ec2: 0.3, ec3: 11.8, ec4: undefined },
 { id: "103", duration: "0.7", flashCharacter: "MO(U) 15S", dutyCycle: "0.22", name: "MO(U) 15S 22%", category: ["Morse", "Common"], fc1: 0.7, fc2: 0.7, fc3: 1.9, fc4: undefined, ec1: 0.5, ec2: 0.5, ec3: 10.7, ec4: undefined },
 { id: "104", duration: "0.7", flashCharacter: "MO(U) 15S", dutyCycle: "0.23333333333333334", name: "MO(U) 15S 23%", category: ["Morse", "Common"], fc1: 0.7, fc2: 0.7, fc3: 2.1, fc4: undefined, ec1: 0.7, ec2: 0.7, ec3: 10.1, ec4: undefined },
 { id: "105", duration: "0.75", flashCharacter: "MO(U) 15S", dutyCycle: "0.23333333333333334", name: "MO(U) 15S 23%", category: ["Morse"], fc1: 0.75, fc2: 0.75, fc3: 2, fc4: undefined, ec1: 0.45, ec2: 0.45, ec3: 10.6, ec4: undefined },
 { id: "106", duration: "1.15", flashCharacter: "MO(U) 15S", dutyCycle: "0.35533333333333333", name: "MO(U) 15S 36%", category: ["Morse"], fc1: 1.15, fc2: 1.15, fc3: 3.03, fc4: undefined, ec1: 0.73, ec2: 0.73, ec3: 8.21, ec4: undefined },
 { id: "107", duration: "1.3", flashCharacter: "MO(U) 15S", dutyCycle: "0.39333333333333337", name: "MO(U) 15S 39%", category: ["Morse"], fc1: 1.3, fc2: 1.3, fc3: 3.3, fc4: undefined, ec1: 0.7, ec2: 0.7, ec3: 7.7, ec4: undefined },
 { id: "108", duration: "0.75", flashCharacter: "MO(U) 15S****", dutyCycle: "0.21", name: "MO(U) 15S**** 21%", category: ["Morse"], fc1: 0.75, fc2: 0.75, fc3: 1.65, fc4: undefined, ec1: 0.15, ec2: 0.15, ec3: 11.55, ec4: undefined },
 { id: "109", duration: "0.45", flashCharacter: "MO(U) 15S*", dutyCycle: "0.15", name: "MO(U) 15S* 15%", category: ["Morse"], fc1: 0.45, fc2: 0.45, fc3: 1.35, fc4: undefined, ec1: 0.45, ec2: 0.45, ec3: 11.85, ec4: undefined },
 { id: "110", duration: "0.55", flashCharacter: "MO(U) 15S**", dutyCycle: "0.16999999999999998", name: "MO(U) 15S** 17%", category: ["Morse"], fc1: 0.55, fc2: 0.55, fc3: 1.45, fc4: undefined, ec1: 0.35, ec2: 0.35, ec3: 11.75, ec4: undefined },
 { id: "111", duration: "0.6", flashCharacter: "MO(U) 15S***", dutyCycle: "0.18000000000000002", name: "MO(U) 15S*** 18%", category: ["Morse"], fc1: 0.6, fc2: 0.6, fc3: 1.5, fc4: undefined, ec1: 0.3, ec2: 0.3, ec3: 11.7, ec4: undefined },
 { id: "112", duration: "0.2", flashCharacter: "MO(U) 10S", dutyCycle: "0.1", name: "MO(U) 10S 10%", category: ["Morse"], fc1: 0.2, fc2: 0.2, fc3: 0.6, fc4: undefined, ec1: 0.8, ec2: 0.8, ec3: 7.4, ec4: undefined },
 { id: "113", duration: "7", flashCharacter: "OC 10S", dutyCycle: "0.7", name: "OC 10S 70%", category: ["Single Occulting"], fc1: 7, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 3, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "114", duration: "7.5", flashCharacter: "OC 10S", dutyCycle: "0.75", name: "OC 10S 75%", category: ["Single Occulting"], fc1: 7.5, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 2.5, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "115", duration: "10", flashCharacter: "OC 15S", dutyCycle: "0.6666666666666666", name: "OC 15S 67%", category: ["Single Occulting"], fc1: 10, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 5, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "116", duration: "2", flashCharacter: "OC 3S", dutyCycle: "0.6666666666666666", name: "OC 3S 67%", category: ["Single Occulting"], fc1: 2, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 1, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "117", duration: "2.5", flashCharacter: "OC 3S", dutyCycle: "0.8333333333333334", name: "OC 3S 83%", category: ["Single Occulting"], fc1: 2.5, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 0.5, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "118", duration: "3", flashCharacter: "OC 4S", dutyCycle: "0.75", name: "OC 4S 75%", category: ["Single Occulting"], fc1: 3, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 1, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "119", duration: "3", flashCharacter: "OC 5S", dutyCycle: "0.6", name: "OC 5S 60%", category: ["Single Occulting"], fc1: 3, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 2, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "120", duration: "4", flashCharacter: "OC 5S", dutyCycle: "0.8", name: "OC 5S 80%", category: ["Single Occulting"], fc1: 4, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 1, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "121", duration: "4.5", flashCharacter: "OC 5S", dutyCycle: "0.9", name: "OC 5S 90%", category: ["Single Occulting"], fc1: 4.5, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 0.5, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "122", duration: "4", flashCharacter: "OC 6S", dutyCycle: "0.6666666666666666", name: "OC 6S 67%", category: ["Single Occulting"], fc1: 4, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 2, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "123", duration: "4.5", flashCharacter: "OC 6S", dutyCycle: "0.75", name: "OC 6S 75%", category: ["Single Occulting"], fc1: 4.5, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 1.5, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "124", duration: "5", flashCharacter: "OC 6S", dutyCycle: "0.8333333333333334", name: "OC 6S 83%", category: ["Single Occulting"], fc1: 5, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 1, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "125", duration: "0.3", flashCharacter: "Q 1.2S", dutyCycle: "0.25", name: "Q 1.2S 25%", category: ["Continuous Quick", "Common"], fc1: 0.3, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 0.9, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "126", duration: "0.5", flashCharacter: "Q 1.2S", dutyCycle: "0.4166666666666667", name: "Q 1.2S 42%", category: ["Continuous Quick", "Common"], fc1: 0.5, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 0.7, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "127", duration: "0.6", flashCharacter: "Q 1.2S", dutyCycle: "0.5", name: "Q 1.2S 50%", category: ["Continuous Quick"], fc1: 0.6, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 0.6, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "128", duration: "0.2", flashCharacter: "Q 1S", dutyCycle: "0.2", name: "Q 1S 20%", category: ["Continuous Quick"], fc1: 0.2, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 0.8, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "129", duration: "0.3", flashCharacter: "Q 1S", dutyCycle: "0.3", name: "Q 1S 30%", category: ["Continuous Quick", "Common"], fc1: 0.3, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 0.7, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "130", duration: "0.4", flashCharacter: "Q 1S", dutyCycle: "0.4", name: "Q 1S 40%", category: ["Continuous Quick"], fc1: 0.4, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 0.6, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "131", duration: "0.5", flashCharacter: "Q 1S", dutyCycle: "0.5", name: "Q 1S 50%", category: ["Continuous Quick", "Common"], fc1: 0.5, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 0.5, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "132", duration: "0.8", flashCharacter: "Q 1S", dutyCycle: "0.8", name: "Q 1S 80%", category: ["Continuous Quick"], fc1: 0.8, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 0.2, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "133", duration: "0.5", flashCharacter: "Q(2) 10S", dutyCycle: "0.1", name: "Q(2) 10S 10%", category: ["Group Quick"], fc1: 0.5, fc2: 0.5, fc3: undefined, fc4: undefined, ec1: 1.5, ec2: 7.5, ec3: undefined, ec4: undefined },
 { id: "134", duration: "0.6", flashCharacter: "Q(2) 10S", dutyCycle: "0.12", name: "Q(2) 10S 12%", category: ["Group Quick"], fc1: 0.6, fc2: 0.6, fc3: undefined, fc4: undefined, ec1: 0.4, ec2: 8.4, ec3: undefined, ec4: undefined },
 { id: "135", duration: "0.3", flashCharacter: "Q(2) 5S", dutyCycle: "0.12", name: "Q(2) 5S 12%", category: ["Group Quick"], fc1: 0.3, fc2: 0.3, fc3: undefined, fc4: undefined, ec1: 0.7, ec2: 3.7, ec3: undefined, ec4: undefined },
 { id: "136", duration: "0.3", flashCharacter: "Q(2) 6S", dutyCycle: "0.09999999999999999", name: "Q(2) 6S 10%", category: ["Group Quick"], fc1: 0.3, fc2: 0.3, fc3: undefined, fc4: undefined, ec1: 0.7, ec2: 4.7, ec3: undefined, ec4: undefined },
 { id: "137", duration: "0.35", flashCharacter: "Q(2) 6S", dutyCycle: "0.11666666666666665", name: "Q(2) 6S 12%", category: ["Group Quick"], fc1: 0.35, fc2: 0.35, fc3: undefined, fc4: undefined, ec1: 0.7, ec2: 4.6, ec3: undefined, ec4: undefined },
 { id: "138", duration: "0.3", flashCharacter: "Q(3) 10S", dutyCycle: "0.09", name: "Q(3) 10S 9%", category: ["Group Quick"], fc1: 0.3, fc2: 0.3, fc3: 0.3, fc4: undefined, ec1: 0.7, ec2: 0.7, ec3: 7.7, ec4: undefined },
 { id: "139", duration: "0.35", flashCharacter: "Q(3) 10S", dutyCycle: "0.10499999999999998", name: "Q(3) 10S 10%", category: ["Group Quick"], fc1: 0.35, fc2: 0.35, fc3: 0.35, fc4: undefined, ec1: 0.65, ec2: 0.65, ec3: 7.65, ec4: undefined },
 { id: "140", duration: "0.6", flashCharacter: "Q(3) 10S", dutyCycle: "0.18", name: "Q(3) 10S 18%", category: ["Group Quick"], fc1: 0.6, fc2: 0.6, fc3: 0.6, fc4: undefined, ec1: 0.6, ec2: 0.6, ec3: 7, ec4: undefined },
 { id: "141", duration: "0.3", flashCharacter: "Q(4) 10S", dutyCycle: "0.12", name: "Q(4) 10S 12%", category: ["Group Quick"], fc1: 0.3, fc2: 0.3, fc3: 0.3, fc4: 0.3, ec1: 0.7, ec2: 0.7, ec3: 0.7, ec4: 6.7 },
 { id: "142", duration: "0.3", flashCharacter: "Q(4) 12S", dutyCycle: "0.09999999999999999", name: "Q(4) 12S 10%", category: ["Group Quick"], fc1: 0.3, fc2: 0.3, fc3: 0.3, fc4: 0.3, ec1: 0.7, ec2: 0.7, ec3: 0.7, ec4: 8.7 },
 { id: "143", duration: "0.35", flashCharacter: "Q(4) 15S", dutyCycle: "0.09333333333333332", name: "Q(4) 15S 9%", category: ["Group Quick"], fc1: 0.35, fc2: 0.35, fc3: 0.35, fc4: 0.35, ec1: 0.7, ec2: 0.7, ec3: 0.7, ec4: 11.5 },
 { id: "144", duration: "0.5", flashCharacter: "Q(4) 20S", dutyCycle: "0.1", name: "Q(4) 20S 10%", category: ["Group Quick", "Common"], fc1: 0.5, fc2: 0.5, fc3: 0.5, fc4: 0.5, ec1: 0.5, ec2: 0.5, ec3: 0.5, ec4: 16.5 },
 { id: "145", duration: "0.4", flashCharacter: "Q(4) 6S", dutyCycle: "0.26666666666666666", name: "Q(4) 6S 27%", category: ["Group Quick"], fc1: 0.4, fc2: 0.4, fc3: 0.4, fc4: 0.4, ec1: 0.6, ec2: 0.6, ec3: 0.6, ec4: 2.6 },
 { id: "146", duration: "0.3", flashCharacter: "Q(5) 10S", dutyCycle: "0.15", name: "Q(5) 10S 15%", category: ["Group Quick"], fc1: 0.3, fc2: 0.3, fc3: 0.3, fc4: 0.3, ec1: 0.7, ec2: 0.7, ec3: 0.7, ec4: 0.7 },
 { id: "147", duration: "0.3", flashCharacter: "Q(5) 20S", dutyCycle: "0.075", name: "Q(5) 20S 8%", category: ["Group Quick", "Common"], fc1: 0.3, fc2: 0.3, fc3: 0.3, fc4: 0.3, ec1: 0.7, ec2: 0.7, ec3: 0.7, ec4: 0.7 },
 { id: "148", duration: "0.5", flashCharacter: "Q(5) 20S", dutyCycle: "0.125", name: "Q(5) 20S 13%", category: ["Group Quick"], fc1: 0.5, fc2: 0.5, fc3: 0.5, fc4: 0.5, ec1: 0.5, ec2: 0.5, ec3: 0.5, ec4: 0.5 },
 { id: "149", duration: "0.3", flashCharacter: "Q(5) 7S", dutyCycle: "0.21428571428571427", name: "Q(5) 7S 21%", category: ["Group Quick"], fc1: 0.3, fc2: 0.3, fc3: 0.3, fc4: 0.3, ec1: 0.7, ec2: 0.7, ec3: 0.7, ec4: 0.7 },
 { id: "150", duration: "0.3", flashCharacter: "Q(6) 10S", dutyCycle: "0.18", name: "Q(6) 10S 18%", category: ["Group Quick"], fc1: 0.3, fc2: 0.3, fc3: 0.3, fc4: 0.3, ec1: 0.7, ec2: 0.7, ec3: 0.7, ec4: 0.7 },
 { id: "151", duration: "0.3", flashCharacter: "Q(6)+LFL 15S", dutyCycle: "0.2533333333333333", name: "Q(6)+LFL 15S 25%", category: ["Group Quick + Long Flash"], fc1: 0.3, fc2: 0.3, fc3: 0.3, fc4: 0.3, ec1: 0.7, ec2: 0.7, ec3: 0.7, ec4: 0.7 },
 { id: "152", duration: "0.35", flashCharacter: "Q(6)+LFL 15S", dutyCycle: "0.21000000000000002", name: "Q(6)+LFL 15S 21%", category: ["Group Quick + Long Flash"], fc1: 0.35, fc2: 0.35, fc3: 0.35, fc4: 0.35, ec1: 0.65, ec2: 0.65, ec3: 0.65, ec4: 0.65 },
 { id: "153", duration: "0.6", flashCharacter: "Q(6)+LFL 15S", dutyCycle: "0.3733333333333333", name: "Q(6)+LFL 15S 37%", category: ["Group Quick + Long Flash"], fc1: 0.6, fc2: 0.6, fc3: 0.6, fc4: 0.6, ec1: 0.6, ec2: 0.6, ec3: 0.6, ec4: 0.6 },
 { id: "154", duration: "0.3", flashCharacter: "Q(9) 15S", dutyCycle: "0.18", name: "Q(9) 15S 18%", category: ["Group Quick"], fc1: 0.3, fc2: 0.3, fc3: 0.3, fc4: 0.3, ec1: 0.7, ec2: 0.7, ec3: 0.7, ec4: 0.7 },
 { id: "155", duration: "0.35", flashCharacter: "Q(9) 15S", dutyCycle: "0.21000000000000002", name: "Q(9) 15S 21%", category: ["Group Quick"], fc1: 0.35, fc2: 0.35, fc3: 0.35, fc4: 0.35, ec1: 0.65, ec2: 0.65, ec3: 0.65, ec4: 0.65 },
 { id: "156", duration: "0.6", flashCharacter: "Q(9) 15S", dutyCycle: "0.36000000000000004", name: "Q(9) 15S 36%", category: ["Group Quick"], fc1: 0.6, fc2: 0.6, fc3: 0.6, fc4: 0.6, ec1: 0.6, ec2: 0.6, ec3: 0.6, ec4: 0.6 },
 { id: "157", duration: "0.15", flashCharacter: "VQ 0.5S", dutyCycle: "0.3", name: "VQ 0.5S 30%", category: ["Continuous Very Quick"], fc1: 0.15, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 0.35, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "158", duration: "0.2", flashCharacter: "VQ 0.5S", dutyCycle: "0.4", name: "VQ 0.5S 40%", category: ["Continuous Very Quick"], fc1: 0.2, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 0.3, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "159", duration: "0.2", flashCharacter: "VQ 0.6S", dutyCycle: "0.3333333333333333", name: "VQ 0.6S 33%", category: ["Continuous Very Quick"], fc1: 0.2, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 0.4, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "160", duration: "0.3", flashCharacter: "VQ 0.6S", dutyCycle: "0.5", name: "VQ 0.6S 50%", category: ["Continuous Very Quick", "Common"], fc1: 0.3, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 0.3, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "161", duration: "0.15", flashCharacter: "VQ(3) 5S", dutyCycle: "0.09", name: "VQ(3) 5S 9%", category: ["Continuous Very Quick"], fc1: 0.15, fc2: 0.15, fc3: 0.15, fc4: undefined, ec1: 0.35, ec2: 0.35, ec3: 3.85, ec4: undefined },
 { id: "162", duration: "0.2", flashCharacter: "VQ(3) 5S", dutyCycle: "0.12000000000000002", name: "VQ(3) 5S 12%", category: ["Continuous Very Quick"], fc1: 0.2, fc2: 0.2, fc3: 0.2, fc4: undefined, ec1: 0.3, ec2: 0.3, ec3: 3.8, ec4: undefined },
 { id: "163", duration: "0.3", flashCharacter: "VQ(3) 5S", dutyCycle: "0.18", name: "VQ(3) 5S 18%", category: ["Continuous Very Quick"], fc1: 0.3, fc2: 0.3, fc3: 0.3, fc4: undefined, ec1: 0.3, ec2: 0.3, ec3: 3.5, ec4: undefined },
 { id: "164", duration: "0.2", flashCharacter: "VQ(6)+LFL 10S", dutyCycle: "0.32", name: "VQ(6)+LFL 10S 32%", category: ["Continuous Very Quick + Long Flash"], fc1: 0.2, fc2: 0.2, fc3: 0.2, fc4: 0.2, ec1: 0.3, ec2: 0.3, ec3: 0.3, ec4: 0.3 },
 { id: "165", duration: "0.3", flashCharacter: "VQ(6)+LFL 10S", dutyCycle: "0.38", name: "VQ(6)+LFL 10S 38%", category: ["Continuous Very Quick + Long Flash"], fc1: 0.3, fc2: 0.3, fc3: 0.3, fc4: 0.3, ec1: 0.3, ec2: 0.3, ec3: 0.3, ec4: 0.3 },
 { id: "166", duration: "0.15", flashCharacter: "VQ(9) 10S", dutyCycle: "0.13499999999999998", name: "VQ(9) 10S 13%", category: ["Continuous Very Quick"], fc1: 0.15, fc2: 0.15, fc3: 0.15, fc4: 0.15, ec1: 0.35, ec2: 0.35, ec3: 0.35, ec4: 0.35 },
 { id: "167", duration: "0.2", flashCharacter: "VQ(9) 10S", dutyCycle: "0.18", name: "VQ(9) 10S 18%", category: ["Continuous Very Quick"], fc1: 0.2, fc2: 0.2, fc3: 0.2, fc4: 0.2, ec1: 0.3, ec2: 0.3, ec3: 0.3, ec4: 0.3 },
 { id: "168", duration: "0.3", flashCharacter: "VQ(9) 10S", dutyCycle: "0.26999999999999996", name: "VQ(9) 10S 27%", category: ["Continuous Very Quick"], fc1: 0.3, fc2: 0.3, fc3: 0.3, fc4: 0.3, ec1: 0.3, ec2: 0.3, ec3: 0.3, ec4: 0.3 },
 { id: "169", duration: "0.5", flashCharacter: "Q(2) 7S", dutyCycle: "0.14285714285714285", name: "Q(2) 7S 14%", category: ["Group Quick"], fc1: 0.5, fc2: 0.5, fc3: undefined, fc4: undefined, ec1: 1, ec2: 5, ec3: undefined, ec4: undefined },
 { id: "170", duration: "0.3", flashCharacter: "Fl(2) 5s", dutyCycle: "0.12", name: "Fl(2) 5s 12%", category: ["Group Flashing"], fc1: 0.3, fc2: 0.3, fc3: undefined, fc4: undefined, ec1: 0.4, ec2: 4, ec3: undefined, ec4: undefined },
 { id: "171", duration: "0.5", flashCharacter: "Fl(2) 10s", dutyCycle: "0.1", name: "Fl(2) 10s 10%", category: ["Group Flashing"], fc1: 0.5, fc2: 0.5, fc3: undefined, fc4: undefined, ec1: 0.7, ec2: 8.3, ec3: undefined, ec4: undefined },
 { id: "172", duration: "0.5", flashCharacter: "Fl(5) 20s", dutyCycle: "0.125", name: "Fl(5) 20s 13%", category: ["Group Flashing"], fc1: 0.5, fc2: 0.5, fc3: 0.5, fc4: 0.5, ec1: 1, ec2: 1, ec3: 1, ec4: 1 },
 { id: "173", duration: "1", flashCharacter: "Fl(2) 10s", dutyCycle: "0.2", name: "Fl(2) 10s 20%", category: ["Group Flashing"], fc1: 1, fc2: 1, fc3: undefined, fc4: undefined, ec1: 2, ec2: 6, ec3: undefined, ec4: undefined },
 { id: "174", duration: "0.4", flashCharacter: "Fl 4s", dutyCycle: "0.1", name: "Fl 4s 10%", category: ["Single Flashing", "Common"], fc1: 0.4, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 3.6, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "175", duration: "0.4", flashCharacter: "Fl(2) 5s", dutyCycle: "0.16", name: "Fl(2) 5s 16%", category: ["Group Flashing"], fc1: 0.4, fc2: 0.4, fc3: undefined, fc4: undefined, ec1: 0.6, ec2: 3.6, ec3: undefined, ec4: undefined },
 { id: "176", duration: "0.4", flashCharacter: "Mo(A) 8s", dutyCycle: "0.3", name: "Mo(A) 8s 30%", category: ["Morse"], fc1: 0.4, fc2: 2, fc3: undefined, fc4: undefined, ec1: 0.6, ec2: 5, ec3: undefined, ec4: undefined },
 { id: "177", duration: "1", flashCharacter: "Fl 2.5s", dutyCycle: "0.4", name: "Fl 2.5s 40%", category: ["Single Flashing"], fc1: 1, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 1.5, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "178", duration: "0.5", flashCharacter: "Fl(3+1) 20 s", dutyCycle: "0.1", name: "Fl(3+1) 20 s 10%", category: ["Composite Group Flash", "Common"], fc1: 0.5, fc2: 0.5, fc3: 0.5, fc4: 0.5, ec1: 1.5, ec2: 1.5, ec3: 4.5, ec4: 10.5 },
 { id: "179", duration: "0.6", flashCharacter: "Fl(3+1) 20 s", dutyCycle: "0.12", name: "Fl(3+1) 20 s 12%", category: ["Composite Group Flash", "Common"], fc1: 0.6, fc2: 0.6, fc3: 0.6, fc4: 0.6, ec1: 1.4, ec2: 1.4, ec3: 4.4, ec4: 10.4 },
 { id: "180", duration: "0.65", flashCharacter: "Fl(3+1) 20 s", dutyCycle: "0.13", name: "Fl(3+1) 20 s 13%", category: ["Composite Group Flash"], fc1: 0.65, fc2: 0.65, fc3: 0.65, fc4: 0.65, ec1: 1.35, ec2: 1.35, ec3: 4.35, ec4: 10.35 },
 { id: "181", duration: "0.7", flashCharacter: "Fl(3+1) 20 s", dutyCycle: "0.13999999999999999", name: "Fl(3+1) 20 s 14%", category: ["Composite Group Flash"], fc1: 0.7, fc2: 0.7, fc3: 0.7, fc4: 0.7, ec1: 1.3, ec2: 1.3, ec3: 4.3, ec4: 10.3 },
 { id: "182", duration: "0.8", flashCharacter: "Fl(3+1) 20 s", dutyCycle: "0.16", name: "Fl(3+1) 20 s 16%", category: ["Composite Group Flash"], fc1: 0.8, fc2: 0.8, fc3: 0.8, fc4: 0.8, ec1: 1.2, ec2: 1.2, ec3: 4.2, ec4: 10.2 },
 { id: "183", duration: "0.5", flashCharacter: "Fl(2) 7s", dutyCycle: "0.14285714285714285", name: "Fl(2) 7s 14%", category: ["Group Flashing"], fc1: 0.5, fc2: 0.5, fc3: undefined, fc4: undefined, ec1: 1.5, ec2: 4.5, ec3: undefined, ec4: undefined },
 { id: "184", duration: "0.5", flashCharacter: "Fl(3) 9s", dutyCycle: "0.16666666666666666", name: "Fl(3) 9s 17%", category: ["Group Flashing"], fc1: 0.5, fc2: 0.5, fc3: 0.5, fc4: undefined, ec1: 1.5, ec2: 1.5, ec3: 4.5, ec4: undefined },
 { id: "185", duration: "2", flashCharacter: "LFL 11s", dutyCycle: "0.18181818181818182", name: "LFL 11s 18%", category: ["Long Flashing"], fc1: 2, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 9, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "186", duration: "0.5", flashCharacter: "Fl(6+1) 15s", dutyCycle: "0.3333333333333333", name: "Fl(6+1) 15s 33%", category: ["Composite Group Flash"], fc1: 0.5, fc2: 0.5, fc3: 0.5, fc4: 0.5, ec1: 0.5, ec2: 0.5, ec3: 0.5, ec4: 0.5 },
 { id: "187", duration: "1.5", flashCharacter: "Mo (0) 12s", dutyCycle: "0.375", name: "Mo (0) 12s 38%", category: ["Morse"], fc1: 1.5, fc2: 1.5, fc3: 1.5, fc4: undefined, ec1: 0.5, ec2: 0.5, ec3: 6.5, ec4: undefined },
 { id: "188", duration: "1.5", flashCharacter: "Mo (0) 15s", dutyCycle: "0.3", name: "Mo (0) 15s 30%", category: ["Morse"], fc1: 1.5, fc2: 1.5, fc3: 1.5, fc4: undefined, ec1: 0.5, ec2: 0.5, ec3: 9.5, ec4: undefined },
 { id: "189", duration: "0.25", flashCharacter: "Q 1S", dutyCycle: "0.25", name: "Q 1S 25%", category: ["Continuous Quick"], fc1: 0.25, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 0.75, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "190", duration: "0.3", flashCharacter: "Q (3)  4.6s", dutyCycle: "0.1956521739130435", name: "Q (3)  4.6s 20%", category: ["Group Quick"], fc1: 0.3, fc2: 0.3, fc3: 0.3, fc4: undefined, ec1: 0.7, ec2: 2, ec3: 1, ec4: undefined },
 { id: "191", duration: "0.5", flashCharacter: "Fl 7.5s", dutyCycle: "0.06666666666666667", name: "Fl 7.5s 7%", category: ["Single Flashing"], fc1: 0.5, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 7, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "192", duration: "0.5", flashCharacter: "Fl (4) 11s", dutyCycle: "0.18181818181818182", name: "Fl (4) 11s 18%", category: ["Group Flashing"], fc1: 0.5, fc2: 0.5, fc3: 0.5, fc4: 0.5, ec1: 1.5, ec2: 1.5, ec3: 1.5, ec4: 4.5 },
 { id: "193", duration: "0.5", flashCharacter: "FL (3) 21s", dutyCycle: "0.07142857142857142", name: "FL (3) 21s 7%", category: ["Group Flashing"], fc1: 0.5, fc2: 0.5, fc3: 0.5, fc4: undefined, ec1: 1.5, ec2: 4.5, ec3: 13.5, ec4: undefined },
 { id: "194", duration: "0.5", flashCharacter: "FL (3) 6s", dutyCycle: "0.25", name: "FL (3) 6s 25%", category: ["Group Flashing"], fc1: 0.5, fc2: 0.5, fc3: 0.5, fc4: undefined, ec1: 0.5, ec2: 0.5, ec3: 3.5, ec4: undefined },
 { id: "195", duration: "0.5", flashCharacter: "FL(3)10s", dutyCycle: "0.15", name: "FL(3)10s 15%", category: ["Group Flashing"], fc1: 0.5, fc2: 0.5, fc3: 0.5, fc4: undefined, ec1: 0.5, ec2: 0.5, ec3: 7.5, ec4: undefined },
 { id: "196", duration: "0.5", flashCharacter: "FL(9)15s", dutyCycle: "0.3", name: "FL(9)15s 30%", category: ["Group Flashing"], fc1: 0.5, fc2: 0.5, fc3: 0.5, fc4: 0.5, ec1: 0.5, ec2: 0.5, ec3: 0.5, ec4: 0.5 },
 { id: "197", duration: "1", flashCharacter: "OC(2)6s", dutyCycle: "0.6666666666666666", name: "OC(2)6s 67%", category: ["Group Occulting"], fc1: 3, fc2: 1, fc3: undefined, fc4: undefined, ec1: 1, ec2: 1, ec3: undefined, ec4: undefined },
 { id: "198", duration: "1", flashCharacter: "OC(3)8s", dutyCycle: "0.625", name: "OC(3)8s 63%", category: ["Group Occulting"], fc1: 3, fc2: 1, fc3: 1, fc4: undefined, ec1: 1, ec2: 1, ec3: 1, ec4: undefined },
 { id: "199", duration: "1", flashCharacter: "OC(4)10s", dutyCycle: "0.6", name: "OC(4)10s 60%", category: ["Group Occulting"], fc1: 3, fc2: 1, fc3: 1, fc4: 1, ec1: 1, ec2: 1, ec3: 1, ec4: 1 },
 { id: "200", duration: "0.5", flashCharacter: "FL(2)6s", dutyCycle: "0.16666666666666666", name: "FL(2)6s 17%", category: ["Group Flashing"], fc1: 0.5, fc2: 0.5, fc3: undefined, fc4: undefined, ec1: 1.5, ec2: 3.5, ec3: undefined, ec4: undefined },
 { id: "201", duration: "0.5", flashCharacter: "FL(1)8s", dutyCycle: "0.0625", name: "FL(1)8s 6%", category: ["Single Flashing"], fc1: 0.5, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 7.5, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "202", duration: "0.3", flashCharacter: "FL(3)15s", dutyCycle: "0.05999999999999999", name: "FL(3)15s 6%", category: ["Group Flashing"], fc1: 0.3, fc2: 0.3, fc3: 0.3, fc4: undefined, ec1: 1.7, ec2: 1.7, ec3: 10.7, ec4: undefined },
 { id: "203", duration: "0.2", flashCharacter: "FL(2)5s", dutyCycle: "0.08", name: "FL(2)5s 8%", category: ["Group Flashing"], fc1: 0.2, fc2: 0.2, fc3: undefined, fc4: undefined, ec1: 0.8, ec2: 3.8, ec3: undefined, ec4: undefined },
 { id: "204", duration: "0.5", flashCharacter: "FL(2)4s", dutyCycle: "0.25", name: "FL(2)4s 25%", category: ["Group Flashing"], fc1: 0.5, fc2: 0.5, fc3: undefined, fc4: undefined, ec1: 1, ec2: 2, ec3: undefined, ec4: undefined },
 { id: "205", duration: "0.3", flashCharacter: "FL(2)4.5s", dutyCycle: "0.13333333333333333", name: "FL(2)4.5s 13%", category: ["Group Flashing"], fc1: 0.3, fc2: 0.3, fc3: undefined, fc4: undefined, ec1: 1, ec2: 2.9, ec3: undefined, ec4: undefined },
 { id: "206", duration: "0.5", flashCharacter: "FL(3)10s", dutyCycle: "0.15", name: "FL(3)10s 15%", category: ["Group Flashing"], fc1: 0.5, fc2: 0.5, fc3: 0.5, fc4: undefined, ec1: 1.5, ec2: 1.5, ec3: 5.5, ec4: undefined },
 { id: "207", duration: "0.5", flashCharacter: "FL(3)15s", dutyCycle: "0.1", name: "FL(3)15s 10%", category: ["Group Flashing"], fc1: 0.5, fc2: 0.5, fc3: 0.5, fc4: undefined, ec1: 1.5, ec2: 1.5, ec3: 10.5, ec4: undefined },
 { id: "208", duration: "0.5", flashCharacter: "Mo(B)16s", dutyCycle: "0.25", name: "Mo(B)16s 25%", category: ["Morse"], fc1: 1.5, fc2: 0.5, fc3: 1.5, fc4: 0.5, ec1: 0.5, ec2: 0.5, ec3: 0.5, ec4: 10.5 },
 { id: "209", duration: "0.15", flashCharacter: "Q 1s", dutyCycle: "0.15", name: "Q 1s 15%", category: ["Continuous Quick", "Common"], fc1: 0.15, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 0.85, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "210", duration: "0.6", flashCharacter: "Fl(2+1) 10s", dutyCycle: "0.18", name: "Fl(2+1) 10s 18%", category: ["Composite Group Flash"], fc1: 0.6, fc2: 0.6, fc3: 0.6, fc4: undefined, ec1: 0.6, ec2: 1.8, ec3: 5.8, ec4: undefined },
 { id: "211", duration: "0.4", flashCharacter: "MO(U) 15s", dutyCycle: "0.13333333333333333", name: "MO(U) 15s 13%", category: ["Morse"], fc1: 0.4, fc2: 0.4, fc3: 1.2, fc4: undefined, ec1: 0.5, ec2: 0.5, ec3: 12, ec4: undefined },
 { id: "212", duration: "0.2", flashCharacter: "Q 1.2s", dutyCycle: "0.16666666666666669", name: "Q 1.2s 17%", category: ["Continuous Quick"], fc1: 0.2, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 1, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "213", duration: "0.2", flashCharacter: "Q(3) 10s", dutyCycle: "0.06000000000000001", name: "Q(3) 10s 6%", category: ["Group Quick"], fc1: 0.2, fc2: 0.2, fc3: 0.2, fc4: undefined, ec1: 1, ec2: 1, ec3: 7.4, ec4: undefined },
 { id: "214", duration: "0.6", flashCharacter: "Q(6)+LFL 15s", dutyCycle: "0.44", name: "Q(6)+LFL 15s 44%", category: ["Group Quick + Long Flash"], fc1: 0.6, fc2: 0.6, fc3: 0.6, fc4: 0.6, ec1: 0.6, ec2: 0.6, ec3: 0.6, ec4: 0.6 },
 { id: "215", duration: "0.2", flashCharacter: "VQ(3) 5s", dutyCycle: "0.12000000000000002", name: "VQ(3) 5s 12%", category: ["Continuous Very Quick"], fc1: 0.2, fc2: 0.2, fc3: 0.2, fc4: undefined, ec1: 0.4, ec2: 0.4, ec3: 3.6, ec4: undefined },
 { id: "216", duration: "0.3", flashCharacter: "VQ(6)+LFL 10s", dutyCycle: "0.48", name: "VQ(6)+LFL 10s 48%", category: ["Continuous Very Quick + Long Flash"], fc1: 0.3, fc2: 0.3, fc3: 0.3, fc4: 0.3, ec1: 0.3, ec2: 0.3, ec3: 0.3, ec4: 0.3 },
 { id: "217", duration: "0.2", flashCharacter: "VQ(9) 10s", dutyCycle: "0.17999999999999994", name: "VQ(9) 10s 18%", category: ["Continuous Very Quick"], fc1: 0.2, fc2: 0.2, fc3: 0.2, fc4: 0.2, ec1: 0.4, ec2: 0.4, ec3: 0.4, ec4: 0.4 },
 { id: "218", duration: "1.5", flashCharacter: "OC (3) 12s", dutyCycle: "0.625", name: "OC (3) 12s 63%", category: ["Group Occulting"], fc1: 4.5, fc2: 1.5, fc3: 1.5, fc4: undefined, ec1: 1.5, ec2: 1.5, ec3: 1.5, ec4: undefined },
 { id: "219", duration: "1", flashCharacter: "OC(4) 12s", dutyCycle: "0.6666666666666666", name: "OC(4) 12s 67%", category: ["Group Occulting"], fc1: 5, fc2: 1, fc3: 1, fc4: 1, ec1: 1, ec2: 1, ec3: 1, ec4: 1 },
 { id: "220", duration: "1", flashCharacter: "FL(3) 12s", dutyCycle: "0.25", name: "FL(3) 12s 25%", category: ["Group Flashing"], fc1: 1, fc2: 1, fc3: 1, fc4: undefined, ec1: 1.5, ec2: 1.5, ec3: 6, ec4: undefined },
 { id: "221", duration: "1", flashCharacter: "FL(4) 15s", dutyCycle: "0.26666666666666666", name: "FL(4) 15s 27%", category: ["Group Flashing"], fc1: 1, fc2: 1, fc3: 1, fc4: 1, ec1: 1.5, ec2: 1.5, ec3: 1.5, ec4: 6.5 },
 { id: "222", duration: "1", flashCharacter: "FL(5) 20s", dutyCycle: "0.25", name: "FL(5) 20s 25%", category: ["Group Flashing"], fc1: 1, fc2: 1, fc3: 1, fc4: 1, ec1: 1.5, ec2: 1.5, ec3: 1.5, ec4: 1.5 },
 { id: "223", duration: "1", flashCharacter: "MO(A)", dutyCycle: "0.3333333333333333", name: "MO(A) 33%", category: ["Morse"], fc1: 1, fc2: 3, fc3: undefined, fc4: undefined, ec1: 1, ec2: 7, ec3: undefined, ec4: undefined },
 { id: "224", duration: "0.5", flashCharacter: "FL(5) 20s SADO", dutyCycle: "0.125", name: "FL(5) 20s SADO 13%", category: ["Group Flashing"], fc1: 0.5, fc2: 0.5, fc3: 0.5, fc4: 0.5, ec1: 1.5, ec2: 1.5, ec3: 1.5, ec4: 1.5 },
 { id: "225", duration: "0.5", flashCharacter: "FL(4) 15s", dutyCycle: "0.13333333333333333", name: "FL(4) 15s 13%", category: ["Group Flashing"], fc1: 0.5, fc2: 0.5, fc3: 0.5, fc4: 0.5, ec1: 2, ec2: 2, ec3: 2, ec4: 7 },
 { id: "226", duration: "0.5", flashCharacter: "FL(5) 20s", dutyCycle: "0.125", name: "FL(5) 20s 13%", category: ["Group Flashing"], fc1: 0.5, fc2: 0.5, fc3: 0.5, fc4: 0.5, ec1: 2, ec2: 2, ec3: 2, ec4: 2 },
 { id: "227", duration: "0.2", flashCharacter: "Q(6)+LFL 15s", dutyCycle: "0.28", name: "Q(6)+LFL 15s 28%", category: ["Group Quick + Long Flash"], fc1: 0.2, fc2: 0.2, fc3: 0.2, fc4: 0.2, ec1: 1, ec2: 1, ec3: 1, ec4: 1 },
 { id: "228", duration: "0.2", flashCharacter: "Q(9) 15s", dutyCycle: "0.11999999999999998", name: "Q(9) 15s 12%", category: ["Group Quick"], fc1: 0.2, fc2: 0.2, fc3: 0.2, fc4: 0.2, ec1: 1, ec2: 1, ec3: 1, ec4: 1 },
 { id: "229", duration: "0.2", flashCharacter: "VQ(6)+LFL 10s", dutyCycle: "0.42000000000000004", name: "VQ(6)+LFL 10s 42%", category: ["Continuous Very Quick + Long Flash"], fc1: 0.2, fc2: 0.2, fc3: 0.2, fc4: 0.2, ec1: 0.4, ec2: 0.4, ec3: 0.4, ec4: 0.4 },
 { id: "230", duration: "1.25", flashCharacter: "OC 2s", dutyCycle: "0.625", name: "OC 2s 63%", category: ["Single Occulting"], fc1: 1.25, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 0.75, ec2: undefined, ec3: undefined, ec4: undefined },
 { id: "231", duration: "1", flashCharacter: "Fl(2) 20s", dutyCycle: "0.1", name: "Fl(2) 20s 10%", category: ["Group Flashing"], fc1: 1, fc2: 1, fc3: undefined, fc4: undefined, ec1: 2, ec2: 16, ec3: undefined, ec4: undefined },
 { id: "238", duration: "0.6", flashCharacter: "CST9", dutyCycle: "0.045000000000000005", name: "CST9 5%", category: ["Common"], fc1: 0.6, fc2: 0.6, fc3: 1.5, fc4: undefined, ec1: 0.3, ec2: 0.3, ec3: 56.7, ec4: undefined },
 { id: "251", duration: "0.7", flashCharacter: "Fl 3.5s", dutyCycle: "0.19999999999999998", name: "Fl 3.5s 20%", category: ["Common"], fc1: 0.7, fc2: undefined, fc3: undefined, fc4: undefined, ec1: 2.8, ec2: undefined, ec3: undefined, ec4: undefined },
];
