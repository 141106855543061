import React, { useMemo } from "react";
import { debounce } from "debounce";
import {
  Radio,
  Form,
  Input,
  InputOnChangeData,
  CheckboxProps,
} from "semantic-ui-react";
import { SettingsBlock } from "./index.styles";
import { Params, Settings } from "../../types";

const items = [
  { key: "1", text: "1 NM", value: "1" },
  { key: "1.5", text: "1.5 NM", value: "1.5" },
  { key: "2", text: "2 NM", value: "2" },
  { key: "2.5", text: "2.5 NM", value: "2.5" },
  { key: "3", text: "3 NM", value: "3" },
  { key: "3.5", text: "3.5 NM", value: "3.5" },
  { key: "4", text: "4 NM", value: "4" },
  { key: "5", text: "5 NM", value: "5" },
  { key: "6", text: "6 NM", value: "6" },
  { key: "7", text: "7 NM", value: "7" },
  { key: "8", text: "8 NM", value: "8" },
  { key: "9", text: "9 NM", value: "9" },
  { key: "10", text: "10 NM", value: "10" },
  { key: "12", text: "12 NM", value: "12" },
  { key: "14", text: "14 NM", value: "14" },
  { key: "16", text: "16 NM", value: "16" },
  { key: "18", text: "18 NM", value: "18" },
  { key: "20", text: "20 NM", value: "20" },
  { key: "25", text: "25 NM", value: "25" },
];

type Props = {
  params: Params;
  updateSettings: (settings: Settings) => void;
};

const PerformanceFilter = ({
  params,
  updateSettings,
}: Props): React.ReactElement => {
  const settings = params.settings;

  function useBestPerformance() {
    updateSettings({
      ...settings,
      performanceOrRangeOrIntensity: {
        ...settings.performanceOrRangeOrIntensity,
        select: "seekBestPerformance",
      },
    });
  }

  function useIntensityOrRange() {
    updateSettings({
      ...settings,
      performanceOrRangeOrIntensity: {
        ...settings.performanceOrRangeOrIntensity,
        select: "range",
      },
    });
  }

  function updateIntensity(intensity: number) {
    updateSettings({
      ...settings,
      performanceOrRangeOrIntensity: {
        ...settings.performanceOrRangeOrIntensity,
        select: "intensity",
        intensity: intensity,
      },
    });
  }

  function updateRange(range: number) {
    updateSettings({
      ...settings,
      performanceOrRangeOrIntensity: {
        ...settings.performanceOrRangeOrIntensity,
        select: "range",
        range,
      },
    });
  }

  return (
    <SettingsBlock>
      <Radio
        label="Seek best performance"
        checked={
          settings.performanceOrRangeOrIntensity.select ===
          "seekBestPerformance"
        }
        onChange={useBestPerformance}
        style={{ fontSize: "1em", marginBottom: "1rem" }}
      />
      <br />
      <Radio
        label="Select a target intensity or range"
        checked={
          settings.performanceOrRangeOrIntensity.select === "range" ||
          settings.performanceOrRangeOrIntensity.select === "intensity"
        }
        onChange={useIntensityOrRange}
        style={{ fontSize: "1em", marginBottom: "0.75rem" }}
      />

      <RangeOrIntensitySelect
        settings={settings}
        updateSettings={updateSettings}
        inputValue={settings.performanceOrRangeOrIntensity.range}
        updateIntensity={updateIntensity}
        updateRange={updateRange}
      />
    </SettingsBlock>
  );
};

type RangeOrIntensityProps = {
  settings: Settings;
  updateSettings: (settings: Settings) => void;
  inputValue: number;
  updateIntensity: (intensity: number) => void;
  updateRange: (range: number) => void;
};

const RangeOrIntensitySelect = (
  props: RangeOrIntensityProps
): React.ReactElement => {
  const selection = props.settings.performanceOrRangeOrIntensity;

  const onRangeChanged = (e: React.FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    if (!target.value || target.value === "on") {
      props.updateRange(selection.range);
      return;
    }
    const value = parseFloat(target.value);
    props.updateRange(value);
  };

  const onIntensityChanged = (
    data: InputOnChangeData | CheckboxProps,
    updateIntensity: (intensity: number) => void
  ) => {
    const value = String(data.value);
    updateIntensity(parseFloat(value));
  };
  const debouncedOnIntensityChanged = useMemo(
    () => debounce(onIntensityChanged, 750),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Form style={{ margin: "0 0 0.75rem 15%" }}>
      <Radio
        label="Range (NM)"
        checked={selection.select === "range"}
        onChange={onRangeChanged}
        style={{ marginBottom: "0.5rem" }}
      />
      {/* <i aria-hidden="true" className="info circle icon ml-1" /> */}
      <Form.Field control="select" onChange={onRangeChanged}>
        {items.map((item) => (
          <option key={item.key} value={item.value}>
            {item.text}
          </option>
        ))}
      </Form.Field>
      <Radio
        label="Eff. Intensity (cd)"
        checked={selection.select === "intensity"}
        onChange={() => props.updateIntensity(selection.intensity)}
        style={{ marginBottom: "0.5rem" }}
      />
      {/* <i aria-hidden="true" className="info circle icon ml-1" /> */}
      <Input
        fluid
        placeholder="0"
        type="number"
        min={0}
        defaultValue={selection.intensity}
        onChange={(_, data) => {
          if (selection.select !== "intensity")
            props.updateIntensity(selection.intensity);
          debouncedOnIntensityChanged(data, props.updateIntensity);
        }}
      />
    </Form>
  );
};

export default PerformanceFilter;
