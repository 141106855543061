import React from "react";
import { Table } from "semantic-ui-react";

type Props = {
  title: string;
  rowContent: React.ReactNode;
  style?: any;
  disabled?: boolean;
};

const TableRow = (props: Props): React.ReactElement => {
  return (
    <Table.Row>
      <Table.Cell style={props.style}>{props.title}</Table.Cell>
      <Table.Cell
        className="numLeft"
        style={{ ...props.style, textAlign: "right" }}
        disabled={props.disabled}
      >
        {props.rowContent}
      </Table.Cell>
    </Table.Row>
  );
};

export default TableRow;
