import React from "react";
import { useParams } from "react-router";
import { Grid, Image, Header } from "semantic-ui-react";
import { Product } from "../../../types";

type Props = {
  products: Product[];
};

const PrintedDescription = ({ products }: Props): React.ReactElement => {
  const { productId } = useParams<{ productId: string }>();
  const product = products.find((p) => p.productId === productId);

  if (!product) return <>Jotain meni pieleen...</>;

  return (
    <Grid className="printed-description">
      <Grid.Column width={5}>
        <Image src={product.productImage} alt={product.productName} rounded />
      </Grid.Column>
      <Grid.Column width={11}>
        <Header
          as="h1"
          content={product.productName}
          subheader={product.productShortDescription}
          style={{ marginBottom: "0.5rem" }}
        />
        <Header as="h3">Description</Header>
        {product.productDescription}
      </Grid.Column>
    </Grid>
  );
};

export default PrintedDescription;
