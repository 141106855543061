import React from "react";
import { Message, Grid } from "semantic-ui-react";

import ProductList from "./ProductList";
import SearchSettings from "../Settings";
import ErrorBoundary from "../ErrorBoundary";
import { Params, Product, Settings } from "../../types";

type Props = {
  params: Params;
  updateSettings: (settings: Settings) => void;
  loading: boolean;
  error: string | null;
  products: Product[];
};

export const ProductSearch = (props: Props): React.ReactElement => {
  const error = props.error;
  const loading = props.loading;

  if (error) {
    return (
      <>
        <Message negative>
          <Message.Header>Oops! {error}</Message.Header>
          <p>
            <b>To resolve this try:</b>
            <li>
              Reload the web page. You can refresh/reload the page, pressing F5
              or Ctrl+R.
            </li>
            <li>
              Clear browser's cache. It could be a problem with the cached
              version of the page you're viewing.
            </li>
            <li>
              Server error issues can be corrected by deleting the cookies
              associated with the site.
            </li>
            <li>
              Contact us directly from our{" "}
              <a href="https://marine.sabik.com/contact-us-en" target="_new">
                Contact Us
              </a>{" "}
              page. There's a good chance that the site's administrators already
              know about the error, but if you suspect they don't, letting them
              know helps both you and them (and everyone else).
            </li>
          </p>
        </Message>
      </>
    );
  }

  return (
    <>
      <ErrorBoundary>
        <Grid>
          <Grid.Column computer={5} tablet={6} mobile={16}>
            <SearchSettings
              loading={loading}
              updateSettings={props.updateSettings}
              params={props.params}
            />
          </Grid.Column>
          <Grid.Column computer={11} tablet={10} mobile={16}>
            <ProductList {...props} loading={loading} />
          </Grid.Column>
        </Grid>
      </ErrorBoundary>
    </>
  );
};
