import React from "react";
import { Form } from "semantic-ui-react";

type Props = {
  value: string;
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
  categories: {
    id: string;
    name: string;
  }[];
  disabled: boolean;
};

const CategoryCodes = (props: Props): React.ReactElement => {
  return (
    <Form>
      <Form.Field
        control="select"
        value={props.value}
        disabled={props.disabled}
        onChange={props.onChange}
      >
        {props.disabled ? (
          <option value="–" key="–">
            –
          </option>
        ) : (
          props.categories.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))
        )}
      </Form.Field>
    </Form>
  );
};

export default CategoryCodes;
