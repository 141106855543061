import React from "react";
import ReactDOM from "react-dom";

import "semantic-ui-css/semantic.min.css";

import "leaflet/dist/leaflet.css";
import "esri-leaflet-geocoder/dist/esri-leaflet-geocoder.css";
import "leaflet/dist/leaflet.js";

import "./assets/fonts/ReplicaStdBold.otf";
import "./assets/fonts/ReplicaStdBoldItalic.otf";
import "./assets/fonts/ReplicaStdItalic.otf";
import "./assets/fonts/ReplicaStdLight.otf";
import "./assets/fonts/ReplicaStdLightItalic.otf";
import "./assets/fonts/ReplicaStdRegular.otf";

import "./index.css";
import "./assets/fonts/ReplicaStdBold.otf";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

const rootSabik = document.getElementById("root");

ReactDOM.render(<App />, rootSabik);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
