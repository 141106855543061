import React from "react";
import { ProductContainer, Loading, FillerCard } from "./index.styles";
import { Card } from "semantic-ui-react";
import LoadingSpinner from "../utils/LoadingSpinner";
import ProductsCard from "./ProductsCard";
import { Product } from "../../types";

type Props = {
  loading: boolean;
  products: Product[];
};

const ProductList = ({ loading, products }: Props): React.ReactElement => {
  // No results
  if (!loading && (!products || products.length === 0)) {
    return (
      <ProductContainer>
        <h3>No lanterns found. Please try different search parameters.</h3>
      </ProductContainer>
    );
  }

  // No sunlight data in that location
  if (!loading && products[0].productId === "SUN-0") {
    return (
      <ProductContainer>
        <h3>No solar data available. Please try a different location.</h3>
      </ProductContainer>
    );
  }

  //This solves TypeError: Cannot read property ‘map’ of undefined
  let productsToRender: React.ReactElement[];

  if (products) {
    productsToRender = products.map((product) => {
      return (
        <ProductsCard
          key={product.productId}
          lantern={product}
          loading={loading}
        />
      );
    });
    // Fill last columns with invisible cards
    for (let i = 0; i < 3; i++) {
      productsToRender.push(<FillerCard key={"fillercard" + i} />);
    }
  } else {
    productsToRender = [];
  }

  return (
    <ProductContainer>
      {loading && (
        <Loading>
          <LoadingSpinner />
        </Loading>
      )}
      <Card.Group itemsPerRow={3}>{productsToRender}</Card.Group>
    </ProductContainer>
  );
};

export default ProductList;
