import React from "react";
import { Form } from "semantic-ui-react";

type Props = {
  value: string;
  onChangeCode: (e: React.FormEvent<HTMLInputElement>) => void;
  options: {
    id: string;
    name: string;
  }[];
  disabled: boolean;
};

const CodeList = (props: Props): React.ReactElement => {
  return (
    <Form>
      <Form.Field
        control="select"
        id="duration"
        value={props.disabled ? "-" : props.value}
        disabled={props.disabled}
        onChange={props.onChangeCode}
      >
        {props.disabled ? (
          <option value="–" key="–">
            –
          </option>
        ) : (
          props.options.map((item) => (
            <option value={item.id} key={item.id}>
              {item.name}
            </option>
          ))
        )}
      </Form.Field>
    </Form>
  );
};

export default CodeList;
