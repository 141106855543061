import styled from "styled-components";
import { Divider as uiDivider, Table } from "semantic-ui-react";

const SettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 0.5rem;
  padding: 1.5rem 1rem;
`;

const SettingsBlock = styled.div`
  font-size: 1em !important;
  width: 100%;
`;

const Divider = styled(uiDivider)`
  width: 100%;
`;

const Geolocation = styled.div`
  padding-left: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.25rem;
  margin-bottom: 1.5rem;
`;

const SettingsContact = styled.div`
  font-size: 1em !important;
  padding: 0.5rem 1.25rem;
`;

const ExplainTableHeader = styled(Table.Header)`
  position: sticky;
  top: -1.5rem;
`;

export {
  SettingsContainer,
  SettingsBlock,
  Divider,
  Geolocation,
  SettingsContact,
  ExplainTableHeader,
};
