import styled from "styled-components";
import { Button, Input, Grid } from "semantic-ui-react";

const LocationGrid = styled(Grid)`
  padding: 0.75rem 0.75rem 1rem 0.75rem !important;
`;

const CoordinateInput = styled(Input)`
  width: 100%;

  input {
    background: #e9ecef !important;
  }
`;

const UpdateButton = styled(Button)`
  width: 100%;
  margin: 0 !important;
  white-space: nowrap;
`;

const CenterMarkerButton = styled.button`
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 1000;
  border: 0;
  cursor: pointer;
  padding: 0.3rem 0.5rem;
  border: 1px solid transparent;

  &:hover {
    background: lightgray !important;
    color: black !important;
    border: 1px solid black;
  }
`;

const WarningButton = styled.button`
  background: transparent;
  border: 0;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
`;

export {
  LocationGrid,
  CoordinateInput,
  UpdateButton,
  CenterMarkerButton,
  WarningButton,
};
