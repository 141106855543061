import React, { useEffect, useReducer } from "react";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import { Element } from "react-scroll";
import { Header, Container } from "semantic-ui-react";
import MapView from "./components/MapView";
import ProductInfo from "./components/ProductInfo";
import { ProductSearch } from "./components/ProductSearch";
import { categoryOptions } from "./components/Settings/FlashCharacter/DataList";
// import BackScrollTop from "./components/utils/BackScrollTop";
import ScrollToTop from "./components/utils/ScrollToTop";
// import Consent from "./components/utils/Consent";
import { SIMULATION_FN_URL } from "./constants";
import { reducer } from "./reducer";
import NavFooter from "./nav/NavFooter";
import NavMenu from "./nav/NavMenu";
import NavSub from "./nav/NavSub";
import { Location, Params, Settings, State } from "./types";

type RouterProps = {
  state: State;
  updateLocation: (location: Location) => void;
  updateSettings: (settings: Settings) => void;
};

const RouterLinks = ({
  state,
  updateLocation,
  updateSettings,
}: RouterProps) => {
  return (
    <Router>
      <ScrollToTop />
      <NavMenu />
      <NavSub />
      <Container>
        <Switch>
          <Route exact path="/">
            {/* Map section */}
            <Element name="mapSec">
              <Container text>
                <br />
                <Header as="h1" textAlign="center">
                  SELECT LOCATION
                  <Header.Subheader>
                    Click and drag the map to the location for your solar
                    product. Or search for an address or postal code. When the
                    marker is where you want it press search.
                  </Header.Subheader>
                </Header>
                <br />
              </Container>

              <MapView
                location={state.params.location}
                updateLocation={updateLocation}
                loading={state.loading}
              />
            </Element>

            {/* Product search section */}
            <div
              id="product-section"
              style={{ marginTop: "-4rem", marginBottom: "4rem" }}
            />
            <Element name="productSec">
              <Container text>
                <br />
                <Header as="h1" textAlign="center">
                  SEARCH FOR SUITABLE OPTION
                  <Header.Subheader>
                    Describe the product features you need and we will narrow
                    down the list of options for you.
                  </Header.Subheader>
                </Header>
                <br />
              </Container>

              <ProductSearch
                params={state.params}
                updateSettings={updateSettings}
                loading={state.loading}
                error={state.error}
                products={state.products}
              />
            </Element>
          </Route>

          {/* Product page */}
          <Route exact path="/products/:productId">
            <ProductInfo products={state.products} params={state.params} />
          </Route>

          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </Container>
      {/* TODO Scroll to top -button? */}
      {/* <BackScrollTop /> */}
      {/* TODO Cookies? */}
      {/* <Consent /> */}
      <NavFooter />
    </Router>
  );
};

const App = () => {
  const [state, dispatch] = useReducer(reducer, null, getDefaultSettings);

  useEffect(() => {
    // This cleans up the Effect Hook function which runs when a component unmount
    let didCancel = false;
    // This is automatically re-triggered if the params change.
    const retrieveLanterns = async () => {
      try {
        dispatch({ type: "start_query" });
        const res = await fetch(SIMULATION_FN_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: buildApiQuery(state.params),
        });
        const data = await res.json();

        if (!didCancel) {
          dispatch({ type: "query_complete", products: data.lanternOutputs });
        }
      } catch (e: any) {
        if (!didCancel) {
          dispatch({
            type: "query_error",
            error: "could not retrieve lanterns: " + e.message,
          });
        }
      }
    };

    retrieveLanterns();

    return () => {
      didCancel = true;
    };
    // Deps that trigger a new query from the server-side
  }, [state.params, dispatch]);

  const updateLocation = (location: Location) => {
    dispatch({ type: "update_location", location });
  };

  const updateSettings = (settings: Settings) => {
    dispatch({ type: "update_settings", settings });
  };

  return (
    <RouterLinks
      state={state}
      updateLocation={updateLocation}
      updateSettings={updateSettings}
    />
  );
};

const DEFAULT_ID = "064";

// Default settings here in one place. This structure is slightly different from what is sent to the server.
// Lat/long are under location and the settings are under "settings".
const getDefaultSettings = (): State => {
  const defaultFlashCharacter = categoryOptions.find(
    (opt: any) => opt.id === DEFAULT_ID
  );

  if (!defaultFlashCharacter)
    throw new Error("Couldn't read default flash character");

  return {
    error: null,
    loading: false,
    products: [],
    params: {
      location: {
        locationLatitude: 35.23,
        locationLongitude: -80.84,
      },
      productFamily: {
        familyName: "all",
      },
      settings: {
        flashCharacter: {
          // For now, make sure you have the category and selected ID in sync (i.e. selected ID is in
          // current category)
          selectedId: defaultFlashCharacter.id,
          select: "category",
          category: "Common",
          dutyCycle: defaultFlashCharacter.dutyCycle,
          duration: defaultFlashCharacter.duration,
        },
        season: {
          jan: true,
          feb: true,
          mar: true,
          apr: true,
          may: true,
          jun: true,
          jul: true,
          aug: true,
          sep: true,
          oct: true,
          nov: true,
          dec: true,
        },
        hasFeatureBluetooth: false,
        hasFeatureGPSMonitoring: false,
        hasFeatureGPSSynchronization: false,
        hasFeatureSatelliteMonitoring: false,
        hasFeatureAIS: false,
        performanceOrRangeOrIntensity: {
          select: "seekBestPerformance",
          range: 1,
          intensity: 1,
          seekBestPerformance: 1,
        },
        seasonalOperation: {
          startDate: "2020-01-01",
          endDate: "2020-12-31",
        },
        ledColor: "red",
        transmissivity: 0.74,
        minimumDaysOfAutonomy: 21,
      },
    },
  };
};

// Because our internal state doesn't match the API params exactly (we have the extra "params" object,
// under which there are location & settings), we use this to build an "API compatible" JSON output
const buildApiQuery = (params: Params) => {
  return JSON.stringify({
    ...params.location,
    ...params.productFamily,
    ...params.settings,
  });
};

export default App;
