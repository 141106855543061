import React, { useState } from "react";
import {
  LocationGrid,
  CoordinateInput,
  UpdateButton,
  WarningButton,
} from "./index.styles";

import MapPage from "./Map";
import { Segment, Message, Grid } from "semantic-ui-react";
import { scroller } from "react-scroll";
import { Location } from "../../types";

const styles = {
  row: {
    padding: 0,
  },
  titleColumn: {
    paddingBottom: "0.5rem",
  },
  inputColumn: {
    padding: "0.25rem 0.25rem 0 0.25rem",
  },
  buttonColumn: {
    padding: "1rem 0.25rem 0 0.25rem",
  },
  mapColumn: {
    padding: "1rem 0.25rem 0 0.25rem",
  },
  alertColumn: {
    padding: "1rem 0.25rem 0 0.25rem",
  },
};

type Props = {
  location: Location;
  updateLocation: (location: Location) => void;
  loading: boolean;
};

function wrapLongitude(lon: number): number {
  return ((lon - 180) % 360) + 180;
}

const MapView = ({
  location,
  updateLocation,
  loading,
}: Props): React.ReactElement => {
  const [latitude, setLatitude] = useState(location.locationLatitude);
  const [longitude, setLongitude] = useState(location.locationLongitude);

  const sameCoordinates = () => {
    const close = (a: number, b: number) => Math.abs(a - b) < 0.01;
    return (
      close(latitude, location.locationLatitude) &&
      close(longitude, location.locationLongitude)
    );
  };

  const submit = () => {
    updateLocation({
      locationLatitude: latitude,
      locationLongitude: longitude,
    });
    setTimeout(() => {
      // Wait alert message to disappear
      scrollToElement("productSec");
    }, 100);
  };

  const mapLocationUpdate = (loc: { lat: number; lng: number }) => {
    setLatitude(loc.lat);
    setLongitude(wrapLongitude(loc.lng));
  };

  const scrollToElement = (element: string) => {
    scroller.scrollTo(element, {
      duration: 1500,
      smooth: true,
      offset: -56,
    });
  };

  return (
    <Segment style={{ margin: 0 }}>
      <LocationGrid>
        {/* Searchbar for tablets and up */}
        <Grid.Row only="tablet computer" style={styles.row}>
          <SearchBar
            latitude={latitude}
            longitude={longitude}
            submit={submit}
          />
        </Grid.Row>

        {/* Map view */}
        <Grid.Column width={16} style={styles.mapColumn}>
          <MapPage
            location={location}
            updateLocation={mapLocationUpdate}
            loading={loading}
          />
        </Grid.Column>

        {/* Location changed warning */}
        {!sameCoordinates() && (
          <Grid.Column width={16} style={styles.alertColumn}>
            <Message warning>
              Map location changed.{" "}
              <WarningButton onClick={submit}>
                Update the simulation.
              </WarningButton>
            </Message>
          </Grid.Column>
        )}

        {/* Searchbar for mobile */}
        <Grid.Row only="mobile" style={styles.row}>
          <SearchBar
            latitude={latitude}
            longitude={longitude}
            submit={submit}
          />
        </Grid.Row>
      </LocationGrid>
    </Segment>
  );
};

type SearchBarProps = {
  latitude: number;
  longitude: number;
  submit: () => void;
};

const SearchBar = ({
  latitude,
  longitude,
  submit,
}: SearchBarProps): React.ReactElement => (
  <>
    {/* Title */}
    <Grid.Column
      only="computer"
      width={3}
      verticalAlign="bottom"
      textAlign="right"
      style={styles.titleColumn}
    >
      <p>Geolocation:</p>
    </Grid.Column>

    {/* Latitude input */}
    <Grid.Column computer={5} tablet={6} mobile={16} style={styles.inputColumn}>
      <div>Latitude</div>
      <CoordinateInput readOnly value={latitude.toFixed(2)} />
    </Grid.Column>

    {/* Longitude input */}
    <Grid.Column computer={5} tablet={6} mobile={16} style={styles.inputColumn}>
      <div>Longitude</div>
      <CoordinateInput readOnly value={longitude.toFixed(2)} />
    </Grid.Column>

    {/* Update button */}
    <Grid.Column
      verticalAlign="bottom"
      computer={3}
      tablet={4}
      mobile={16}
      textAlign="right"
      style={styles.buttonColumn}
    >
      <UpdateButton onClick={submit}>Update location</UpdateButton>
    </Grid.Column>
  </>
);

export default MapView;
