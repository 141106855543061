import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import Jump from "react-reveal/Jump";
import { HashLink as Link } from "react-router-hash-link";

import { Label } from "semantic-ui-react";

const Wrapper = styled.div`
  & .ui.tag.label {
    font-size: 14px;
    cursor: pointer;
  }
`;

function GoBack() {
  let history = useHistory();

  function handleClick() {
    history.push("/#productSec");
  }

  return (
    <Wrapper>
      <Jump delay={500}>
        <Label
          smooth
          tag
          as={Link}
          to="/#product-section"
          onClick={handleClick}
          floated="left"
          className="button"
        >
          Go Back
        </Label>
      </Jump>
    </Wrapper>
  );
}

export default GoBack;
