import React from "react";
import { Product } from "../../types";
import ProductInfoTable from "../common/ProductInfoTable";
import TableRow from "../common/ProductInfoTable/TableRow";
import { DivergenceRow } from "./renderDivergence";

type Props = {
  lantern: Product;
};

const ProductTable = ({ lantern }: Props): React.ReactElement => {
  const { effectiveIntensity, range, daysOfAutonomy, minimumALR } = lantern;

  return (
    <div style={{ fontSize: "0.85em" }}>
      <ProductInfoTable unstackable>
        <TableRow
          title="Effective Intensity"
          rowContent={roundWhenLess(effectiveIntensity, 1, false)}
        />
        <TableRow title="Range" rowContent={round1(range)} />
        {daysOfAutonomy > 90 ? (
          <TableRow title="Days of Autonomy" rowContent="Over 90 days." />
        ) : (
          <TableRow
            title="Days of Autonomy"
            rowContent={roundWhenLess(daysOfAutonomy, 1, false)}
          />
        )}
        <TableRow title="Minimum ALR" rowContent={round(minimumALR)} />
        <DivergenceRow lantern={lantern} />
      </ProductInfoTable>
    </div>
  );
};

// https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary
const round = (num: number) => {
  return Math.round((num + Number.EPSILON) * 100) / 100;
};

const round1 = (num: number) => {
  return Math.round((num + Number.EPSILON) * 10) / 10;
};

const roundWhenLess = (num: number, bound: number, useCeil = true) => {
  if (num < bound) return round(num);
  else if (useCeil) return Math.ceil(num);
  else return Math.round(num);
};

export default ProductTable;
