import React from "react";
import styled from "styled-components";
import { Dropdown, DropdownProps } from "semantic-ui-react";

// Correct icon position with long options
const DropdownWrapper = styled.div`
  & > div {
    position: relative;
  }

  & > div > i {
    position: absolute !important;
    top: 38%;
    right: 0.5rem;
  }
`;

const categories = [
  {
    key: "Common",
    text: "Common",
    value: "Common",
  },
  {
    key: "Isophase",
    text: "Isophase",
    value: "Isophase",
  },
  {
    key: "Single Flashing",
    text: "Single Flashing",
    value: "Single Flashing",
  },
  {
    key: "Single Occulting",
    text: "Single Occulting",
    value: "Single Occulting",
  },
  {
    key: "Group Flashing",
    text: "Group Flashing",
    value: "Group Flashing",
  },
  {
    key: "Long Flashing",
    text: "Long Flashing",
    value: "Long Flashing",
  },
  {
    key: "Composite Group Flash",
    text: "Composite Group Flash",
    value: "Composite Group Flash",
  },
  {
    key: "Continuous Quick",
    text: "Continuous Quick",
    value: "Continuous Quick",
  },
  {
    key: "Group Quick",
    text: "Group Quick",
    value: "Group Quick",
  },
  {
    key: "Group Quick + Long Flash",
    text: "Group Quick + Long Flash",
    value: "Group Quick + Long Flash",
  },
  {
    key: "Continuous Very Quick",
    text: "Continuous Very Quick",
    value: "Continuous Very Quick",
  },
  {
    key: "Continuous Very Quick + Long Flash",
    text: "Continuous Very Quick + Long Flash",
    value: "Continuous Very Quick + Long Flash",
  },
  {
    key: "Morse",
    text: "Morse",
    value: "Morse",
  },
  {
    key: "Group Occulting",
    text: "Group Occulting",
    value: "Group Occulting",
  },
  {
    key: "Others",
    text: "Others",
    value: "Others",
  },
];

type Props = {
  handleSelect: (data: DropdownProps) => void;
  value: string;
  includeAll?: boolean;
  scrolling?: boolean;
  disabled?: boolean;
  style?: any;
};

const CategoriesList = (props: Props): React.ReactElement => {
  let items = categories;
  if (props.includeAll) {
    items = [
      {
        key: "All",
        text: "All",
        value: "All",
      },
      ...items,
    ];
  }
  return (
    <DropdownWrapper>
      <Dropdown
        button
        fluid
        scrolling={props.scrolling}
        disabled={props.disabled}
        options={items}
        value={props.value || items[0].value}
        onChange={(e, data) => props.handleSelect(data)}
        style={props.style}
      />
    </DropdownWrapper>
  );
};

export default CategoriesList;
