import * as React from "react";
import { Form, Button, Grid } from "semantic-ui-react";
import { Params, Settings } from "../../types";
import { SettingsBlock } from "./index.styles";

type Props = {
  params: Params;
  updateSettings: (settings: Settings) => void;
};

const Season = ({ params, updateSettings }: Props): React.ReactElement => {
  const settings = params.settings;
  const [localseason, setLocalSeason] = React.useState(settings.season);

  function onChange(e: React.FormEvent<HTMLInputElement>) {
    const target = e.target as HTMLInputElement;
    setLocalSeason({ ...localseason, [target.id]: target.checked });
  }

  function update() {
    updateSettings({ ...settings, season: localseason });
  }

  return (
    <SettingsBlock>
      <Form>
        <Grid
          padded="horizontally"
          style={{
            margin: "0 auto 1.5rem auto",
          }}
        >
          {months.map((month) => (
            <Checkbox
              label={month[0].toUpperCase() + month.substring(1)}
              id={month}
              key={month}
              onChange={onChange}
              defaultChecked={localseason[month]}
            />
          ))}
        </Grid>
      </Form>

      <Button onClick={update} variant="primary" style={{ float: "right" }}>
        Update use time
      </Button>
    </SettingsBlock>
  );
};

const Checkbox = ({
  label,
  id,
  onChange,
  defaultChecked,
}: {
  label: string;
  id: string;
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
  defaultChecked: boolean;
}) => (
  <Grid.Column
    width={4}
    style={{
      padding: "0.25rem",
    }}
  >
    <Form.Checkbox
      label={label}
      id={id}
      onChange={onChange}
      defaultChecked={defaultChecked}
    />
  </Grid.Column>
);

const months: (
  | "jan"
  | "feb"
  | "mar"
  | "apr"
  | "may"
  | "jun"
  | "jul"
  | "aug"
  | "sep"
  | "oct"
  | "nov"
  | "dec"
)[] = [
  "jan",
  "feb",
  "mar",
  "apr",
  "may",
  "jun",
  "jul",
  "aug",
  "sep",
  "oct",
  "nov",
  "dec",
];

export default Season;
