import { Location, Product, Settings, State } from "./types";

type UpdateLocation = {
  type: "update_location";
  location: Location;
};
type UpdateSettings = {
  type: "update_settings";
  settings: Settings;
};
type StartQuery = {
  type: "start_query";
};
type QueryComplete = {
  type: "query_complete";
  products: Product[];
};
type QueryError = {
  type: "query_error";
  error: string;
};

const reducer = (
  state: State,
  action:
    | UpdateLocation
    | UpdateSettings
    | StartQuery
    | QueryComplete
    | QueryError
): State => {
  switch (action.type) {
    case "update_location": {
      return {
        ...state,
        params: { ...state.params, location: action.location },
      };
    }
    case "update_settings": {
      return {
        ...state,
        params: { ...state.params, settings: action.settings },
      };
    }
    case "start_query":
      return { ...state, loading: true, error: null };

    case "query_complete":
      return { ...state, loading: false, products: action.products };

    case "query_error":
      return { ...state, loading: false, products: [], error: action.error };

    default:
      return state;
  }
};

export { reducer };
