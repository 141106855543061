import React from "react";

class ErrorBoundary extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      childVisible: false,
      hasError: false,
    };
  }

  componentDidCatch() {
    this.setState({ hasError: true, childVisible: true });
  }

  render() {
    if (this.state.hasError) {
      return (
        <h3
          className="mb-5"
          style={{ marginBottom: "5rem", textAlign: "center" }}
        >
          Oops!!! Something went wrong
        </h3>
      );
    } else {
      return this.props.children;
    }
  }
}

export default ErrorBoundary;
