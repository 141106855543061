import React from "react";
import { Container } from "semantic-ui-react";
import styled from "styled-components";

const NavBar = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2000;
  display: flex;
  align-items: center;
  height: 4rem;
  background: #002f6c;
  font-size: 14px;
  font-weight: 400 !important;

  & a:last-child {
    padding: 0;
  }

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const Link = styled.a`
  padding-right: 2rem;
  color: #fff !important;
  opacity: 0.5;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: #fff !important;
    opacity: 0.9;
  }
`;
const ActiveLink = styled(Link)`
  opacity: 1;
  font-weight: bold;
`;

// TODO: Hamburger menu?

const NavMenu = (): React.ReactElement => {
  return (
    <NavBar>
      <Container>
        <ActiveLink href="/#">Home</ActiveLink>

        <Link href="https://marine.sabik.com/online-tools" target="_new">
          Online tools
        </Link>

        <Link href="https://marine.sabik.com/contact-us-en" target="_new">
          Contact Us
        </Link>

        {/* <Link href="https://marine.sabik.com/about" target="_new">
          About
        </Link> */}
      </Container>
    </NavBar>
  );
};

export default NavMenu;
