import React from "react";
import { useParams } from "react-router-dom";
import { Label } from "semantic-ui-react";
import { Product, Option } from "../../../types";
import ProductInfoTable from "../../common/ProductInfoTable";
import TableRow from "../../common/ProductInfoTable/TableRow";

// Returns corresponding label with input 'x', 'o' or null
const getLabel = (option: Option) => {
  if (option === "x")
    return (
      <Label color="green" horizontal>
        Included
      </Label>
    );
  if (option === "o")
    return (
      <Label color="yellow" horizontal>
        Optional
      </Label>
    );
  return (
    <Label horizontal style={{ margin: 0 }}>
      Not included
    </Label>
  );
};

type Props = {
  products: Product[];
};

const OptionsTable = ({ products }: Props): React.ReactElement => {
  const { productId } = useParams<{ productId: string }>();
  const product = products.find((p) => p.productId === productId);

  if (!product) return <>Jotain meni pieleen...</>;

  const {
    productOptionsGPSSynchronization,
    productOptionsSatelliteMonitoring,
    productOptionsGSMMonitoring,
    productOptionsBluetooth,
    productOptionsAIS,
  } = product;

  return (
    <ProductInfoTable header="Options">
      <TableRow
        title="GPS Flash Synchronization"
        rowContent={getLabel(productOptionsGPSSynchronization)}
      />
      <TableRow
        title="Satellite Monitoring"
        rowContent={getLabel(productOptionsSatelliteMonitoring)}
      />
      <TableRow
        title="GSM Monitoring"
        rowContent={getLabel(productOptionsGSMMonitoring)}
      />
      <TableRow
        title="Bluetooth"
        rowContent={getLabel(productOptionsBluetooth)}
      />
      <TableRow
        title="AIS Transponder"
        rowContent={getLabel(productOptionsAIS)}
      />
    </ProductInfoTable>
  );
};

export default OptionsTable;
