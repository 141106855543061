import React, { useState } from "react";
import { Form, Grid } from "semantic-ui-react";
import { Params, Settings } from "../../../types";

const items = [
  { key: "white", text: "White", value: "white" },
  { key: "green", text: "Green", value: "green" },
  { key: "yellow", text: "Yellow", value: "yellow" },
  { key: "red", text: "Red", value: "red" },
];
// Used in colourStyles
const colors = ["#EAEAEA", "#03AE57", "#FFBB28", "#FF0000"];

type Props = {
  params: Params;
  updateSettings: (settings: Settings) => void;
};

const LedColor = ({ params, updateSettings }: Props): React.ReactElement => {
  const settings = params.settings;

  const [fieldValue, setFieldValue] = useState(
    settings.ledColor || items[3].value
  );

  function onChangeColorValue(e: React.FormEvent<HTMLInputElement>) {
    const target = e.target as HTMLInputElement;
    if (!target) return;
    const value = target.value.toString();
    updateSettings({ ...settings, ledColor: value });
    setFieldValue(value);
  }

  return (
    <Grid.Row style={{ alignItems: "center" }}>
      <Grid.Column computer={5} tablet={8} mobile={8}>
        LED Color
      </Grid.Column>
      <Grid.Column computer={11} tablet={8} mobile={8}>
        <Form>
          <Form.Field
            control="select"
            value={fieldValue}
            onChange={onChangeColorValue}
          >
            {items.map((item, i) => (
              <option
                key={item.key}
                value={item.value}
                style={{ color: colors[i] }}
              >
                {item.text}
              </option>
            ))}
          </Form.Field>
        </Form>
      </Grid.Column>
    </Grid.Row>
  );
};

export default LedColor;
