import React from "react";
import { SettingsBlock, Geolocation } from "./index.styles";
import { Button } from "semantic-ui-react";
import { scroller } from "react-scroll";
import { Params } from "../../types";

const scrollToMap = () => {
  scroller.scrollTo("mapSec", {
    duration: 1500,
    delay: 100,
    smooth: true,
    offset: -56,
  });
};

type Props = {
  params: Params;
};

const Location = ({ params }: Props): React.ReactElement => (
  <SettingsBlock>
    <Geolocation>
      <b>Latitude:</b> {params.location.locationLatitude.toFixed(2)}
      <b>Longitude:</b> {params.location.locationLongitude.toFixed(2)}
    </Geolocation>
    <Button onClick={scrollToMap} variant="primary" style={{ float: "right" }}>
      Change location
    </Button>
  </SettingsBlock>
);

export default Location;
