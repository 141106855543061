import styled from "styled-components";
import { Segment } from "semantic-ui-react";

const PageHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  align-items: flex-end;
`;

const ProductSegment = styled(Segment)`
  padding: 2rem !important;
  font-size: 1em !important;
  position: relative !important;

  & > table {
    margin-top: 2rem !important;
  }

  @media (max-width: 767px) {
    padding-top: 7rem !important;
  }

  .printed-description {
    display: none;
  }

  @media only print {
    padding: 0 2rem !important;
    margin: none !important;
    box-shadow: none !important;
    border: none !important;
    overflow: hidden !important;

    .printed-description {
      display: block;
    }
    .description {
      display: none;
    }
  }
`;

const ButtonContainer = styled.div`
  position: absolute;
  top: 2rem;
  right: 2rem;
  z-index: 100;

  & > * {
    float: right;
    margin-left: 0.5rem !important;
  }

  @media (max-width: 767px) {
    left: 2rem;
    display: flex;
    justify-content: center;

    & > * {
      float: left;
      margin: 0 0.25rem !important;
    }
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    width: 200px;

    & > *:first-child {
      margin-bottom: 0.5rem;
    }
  }

  @media only print {
    display: none;
  }
`;

export { PageHeader, ProductSegment, ButtonContainer };
