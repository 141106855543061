import React, { useRef } from "react";
import { ProductSegment, ButtonContainer } from "./index.styles";
import GoBack from "../utils/GoBackButton";
import { Grid, Button, Icon, Header, Divider } from "semantic-ui-react";
import { Redirect, useParams } from "react-router-dom";

import {
  Description,
  OptionsTable,
  LocationTable,
  PerformanceTable,
  PrintedDescription,
} from "./InfoSections";
import { Params, Product } from "../../types";
import { useReactToPrint } from "react-to-print";

type Props = {
  products: Product[];
  params: Params;
};

const ProductInfo = (props: Props): React.ReactElement => {
  const { productId } = useParams<{ productId?: string }>();

  const printedComponentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => printedComponentRef.current,
  });

  if (props.products.length === 0) {
    return <Redirect to="/" />;
  }

  const product = props.products.find((p) => p.productId === productId);
  if (!product) {
    return <div>Product not found!</div>;
  }

  return (
    <>
      <Divider hidden />
      <Grid stackable reversed="mobile" verticalAlign="bottom">
        <Grid.Column width={3}>
          <GoBack />
        </Grid.Column>
        <Grid.Column width={10}>
          <Header as="h1" textAlign="center">
            Product Details
            <Header.Subheader>
              Detailed specifications based on in-depth solar simulation for
              your selected location and configuration options.
            </Header.Subheader>
          </Header>
        </Grid.Column>
      </Grid>
      <Divider hidden />

      <div ref={printedComponentRef}>
        <ProductSegment>
          <ButtonContainer>
            <Button as="a" href={product.productPage} target="_new" icon>
              <Icon name="external alternate" /> Product information
            </Button>
            <Button onClick={handlePrint} icon>
              <Icon name="file pdf outline" /> Print
            </Button>
          </ButtonContainer>

          <Description {...props} />
          <PrintedDescription {...props} />
          <LocationTable {...props} />
          <OptionsTable {...props} />
          <PerformanceTable {...props} />
        </ProductSegment>
      </div>
    </>
  );
};

export default ProductInfo;
