import React, { Component } from "react";
import { CenterMarkerButton } from "./index.styles";

import * as ELG from "esri-leaflet-geocoder";
import L, { LatLngTuple, LeafletEvent } from "leaflet";
import { Map, Marker, TileLayer, Viewport } from "react-leaflet";
import { Location } from "../../types";
import { LatLng } from "leaflet";

// please replace this with your own mapbox token!
const token =
  "pk.eyJ1Ijoicm9kaGFtIiwiYSI6ImNrYnE2aHlnOTJqaHAyc3B2MnJ3dG54aHYifQ.GQU0QY4zslaPKvP6ohhSmA";
const UrLink =
  "https://api.mapbox.com/styles/v1/mapbox/streets-v10/tiles/256/{z}/{x}/{y}@2x?access_token=" +
  token;
const mapAttrib =
  '&copy; <a href="https://marine.sabik.com/" target="_new">Sabik Marine</a>';

// Import marker icons
delete (L.Icon.Default.prototype as any)._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    "https://unpkg.com/leaflet@1.4.0/dist/images/marker-icon-2x.png",
  iconUrl: "https://unpkg.com/leaflet@1.4.0/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.4.0/dist/images/marker-shadow.png",
});

const DEFAULT_LOCATION = new LatLng(35.23, -80.84);
const DEFAULT_ZOOM = 6;

function CenterMarker({ onClick }: { onClick: () => void }) {
  return (
    <CenterMarkerButton onClick={onClick} className="center-marker-btn">
      Center Marker
    </CenterMarkerButton>
  );
}

type Props = {
  location: Location;
  updateLocation: (loc: { lat: number; lng: number }) => void;
  loading: boolean;
};

type State = {
  center: LatLng;
  viewport: Viewport;
  marker: LatLng;
  currentPos: LatLng | null;
  zoom: number;
  draggable: boolean;
  hasError: boolean;
};

class MapPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      center: DEFAULT_LOCATION,
      viewport: {
        center: [DEFAULT_LOCATION.lat, DEFAULT_LOCATION.lng],
        zoom: DEFAULT_ZOOM,
      },
      marker: DEFAULT_LOCATION,
      currentPos: null,
      zoom: DEFAULT_ZOOM,
      draggable: true,
      hasError: false,
    };
  }

  leafletMap: Map | null | undefined;

  handleSearch = () => {
    const map = this.leafletMap?.leafletElement;
    if (!map) return;
    // @ts-ignore
    const searchControl = new ELG.Geosearch({ expanded: true }).addTo(map);
    const results = new L.LayerGroup().addTo(map);

    searchControl.on("results", (data: any) => {
      results.clearLayers();
      let result = data.results[0];
      this.setState({
        marker: result.latlng,
      });
      this.props.updateLocation(result.latlng);
    });
  };

  componentDidMount() {
    this.handleSearch();
  }

  /**
   * When the marker is dragged you get the lat and long using the functions available from event object.
   * Use geocode to get the address, city, area and state from the lat and lng positions.
   * And then set those values in the state.
   *
   * @param event
   */

  updatePosition = (event: LeafletEvent) => {
    const marker = event.target;
    if (marker !== null) {
      this.setState({
        marker: marker.getLatLng(),
      });
      this.props.updateLocation(marker.getLatLng());
    }
  };

  centerMarker = () => {
    const latlng = this.state.viewport.center;
    if (!latlng) return;
    const coordsObject = L.latLng(latlng[0], latlng[1]);
    this.setState({
      marker: L.latLng(latlng[0], latlng[1]),
    });
    this.props.updateLocation(coordsObject);
  };

  onViewportChanged = (viewport: Viewport) => {
    this.setState({ viewport });
  };

  render(): React.ReactElement {
    const position = this.state.center;
    const markerPosition: LatLngTuple = [
      this.state.marker.lat,
      this.state.marker.lng,
    ];

    return (
      <Map
        onViewportChanged={this.onViewportChanged}
        viewport={this.state.viewport}
        style={{ height: "500px", width: "100%" }}
        maxZoom={20}
        attributionControl={true}
        zoomControl={true}
        doubleClickZoom={false}
        scrollWheelZoom={true}
        dragging={true}
        animate={true}
        center={position}
        zoom={this.state.zoom}
        ref={(m) => {
          this.leafletMap = m;
        }}
      >
        <CenterMarker onClick={this.centerMarker} />
        <TileLayer attribution={mapAttrib} url={UrLink} />
        <Marker
          draggable={this.state.draggable}
          onDragend={(e: LeafletEvent) => this.updatePosition(e)}
          position={markerPosition}
        ></Marker>
      </Map>
    );
  }
}

export default MapPage;
