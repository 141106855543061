import React from "react";
import logo from "../assets/logo.png";
import { Header, Grid, Container, Image } from "semantic-ui-react";
import styled from "styled-components";

const Nav = styled.div`
  margin-top: 4rem;
  min-height: 8rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Link = styled.a`
  padding-left: 2rem;
  transition: all 0.2s ease-in-out;
`;

const title = "MARINE LANTERN SELECTOR TOOL";

const NavSub = (): React.ReactElement => (
  <Nav>
    <Container>
      <Grid verticalAlign="middle">
        <Grid.Column floated="left" width={3} only="computer">
          <Image
            src={logo}
            as="a"
            href="https://marine.sabik.com/"
            size="small"
          />
        </Grid.Column>
        <Grid.Column
          only="computer"
          computer={13}
          largeScreen={10}
          widescreen={10}
          textAlign="center"
        >
          <Header
            as="h1"
            style={{
              paddingLeft: "1.5rem",
              color: "#002f6c",
              paddingTop: "0.5rem",
            }}
          >
            {title}
          </Header>
        </Grid.Column>
        <Grid.Column
          floated="right"
          only="large screen"
          largeScreen={3}
          textAlign="right"
        >
          {/* 
          <Link
            className="navsub-tab"
            href="https://marine.sabik.com/tool"
            target="_new"
          >
            Tool
          </Link>
          */}
          <Link href="https://marine.sabik.com/about" target="_new">
            About
          </Link>
        </Grid.Column>

        {/* Smaller sizes - stacked */}
        <Grid.Column
          width={16}
          only="mobile tablet"
          textAlign="center"
          style={{ marginTop: "1rem" }}
        >
          <Image
            src={logo}
            as="a"
            href="https://marine.sabik.com/"
            size="small"
          />
        </Grid.Column>
        <Grid.Column
          width={16}
          only="tablet"
          textAlign="center"
          style={{ marginBottom: "1rem" }}
        >
          <Header as="h1">{title}</Header>
        </Grid.Column>
      </Grid>
    </Container>
  </Nav>
);

export default NavSub;
